import { useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";

const AdminClientPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { name } = state;
  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    navigate("/admin/login");
  };
  return (
    <div className="d-flex flex-column min-vh-100">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="navbar-brand ml-3 mb-1">{name}</div>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/admin/details/client/form"
                state={state}
              >
                Client Details
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/admin/details/subscriptions"
                state={state}
              >
                Subscriptions
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="align-items-center mr-3">
          <button className="btn btn-danger" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </nav>
      <div className="flex-grow-1">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminClientPage;
