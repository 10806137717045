import { ClientApplication, createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";

interface AllParams {
  isVerificationComplete: boolean;
  isShopify: boolean;
  shop: string;
  app_url: string;
  isInstallationRequired: boolean;
  isFirstInstallation: boolean;
  isEmbedded: number;
  host: string;
  api_key: string;
  app: ClientApplication | undefined;
  redirect: Redirect.Redirect | undefined;
}
var actualParams: AllParams = {
  isVerificationComplete: false,
  isShopify: false,
  shop: "",
  app_url: "",
  isInstallationRequired: true,
  isFirstInstallation: true,
  isEmbedded: 0,
  host: "",
  api_key: "",
  app: undefined,
  redirect: undefined,
};

export function setApp() {
  const config = {
    apiKey: getApiKey(),
    host: getHost(),
    forceRedirect: true,
  };
  actualParams.app = createApp(config);
  return actualParams.app
}

export function setRedirect(app: ClientApplication) {
    actualParams.redirect = Redirect.create(app);
    return actualParams.redirect;
}

export function setIsVerificationComplete(isVerificationComplete: boolean) {
  actualParams.isVerificationComplete = isVerificationComplete;
}

export function setIsShopify(isShopify: boolean) {
  actualParams.isShopify = isShopify;
}

export function setShop(shop: string) {
  actualParams.shop = shop;
}

export function setAppUrl(app_url: string) {
  actualParams.app_url = app_url;
}

export function setIsInstallationRequired(isInstallationRequired: boolean) {
  actualParams.isInstallationRequired = isInstallationRequired;
}

export function setIsEmbedded(isEmbedded: number) {
  actualParams.isEmbedded = isEmbedded;
}

export function setIsFirstInstallation(isFirstInstallation: boolean) {
  actualParams.isFirstInstallation = isFirstInstallation;
}

export function setApiKey(api_key: string) {
  actualParams.api_key = api_key;
}

export function setHost(host: string) {
  actualParams.host = host;
}

export function getApp() {
  if (!actualParams.app) return setApp();
  return actualParams.app;
}

export function getRedirect() {
  if (!actualParams.redirect) return setRedirect(getApp());
  return actualParams.redirect;
}

export function getIsVerificationComplete() {
  return actualParams.isVerificationComplete;
}

export function getShop(): string {
  return actualParams.shop;
}

export function getIsShopify(): boolean {
  return actualParams.isShopify;
}

export function getAppUrl(): string {
  return actualParams.app_url;
}

export function getIsInstallationRequired() {
  return actualParams.isInstallationRequired;
}

export function getIsFirstInstallation() {
  return actualParams.isFirstInstallation;
}

export function getIsEmbedded() {
  return actualParams.isEmbedded;
}

export function getHost() {
  return actualParams.host;
}

export function getApiKey() {
  return actualParams.api_key;
}
