import { set } from 'lodash';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AdminLogin: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setError('');

      setLoading(true);
      await fetch('https://vto-api.metadome.ai/auth/admin/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (data.error) {
            setError(data.error);
          } else {
            console.log(data);
            localStorage.setItem('access_token', (data as any)['access_token']);

            localStorage.setItem(
              'refresh_token',
              (data as any)['refresh_token'],
            );
            navigate('/admin/dashboard');
          }
        });
    } catch (e) {
      setError('Failed to login');
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container mt-5'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <h2>Admin Login</h2>
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <label htmlFor='email' className='form-label'>
                Email address
              </label>
              <input
                type='email'
                className='form-control'
                id='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='password' className='form-label'>
                Password
              </label>
              <input
                type='password'
                className='form-control'
                id='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className='alert alert-danger'>{error}</div>}
            {loading ? (
              <div className='d-flex justify-content-center'>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <button type='submit' className='btn btn-primary'>
                Login
              </button>
            )}
            <p className='mt-2'>
              Interested to join us!
              <Link
                className='nav-link d-inline ml-1 p-1 text-primary rounded'
                to='/selector'
              >
                Click here!
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
