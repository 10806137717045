import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom";
import ClientPage from "./clientPage";
import ProductAppPage from "./ProductAppPage";

const SelectTab = () => {
    const [selectedTab, setSelectedTab] = useState('form')
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            marginTop: '20px',
            marginLeft: '10px'
        }}>
            <div style={{
                display: 'flex',
                gap: '10px',
                border: '1px solid grey',
                borderRadius: '40px',
                padding: '10px',
                width: 'fit-content',
            }}>
                <div style={{
                    border: '1px solid grey',
                    borderRadius: '20px',
                    padding: '5px 18px',
                    background: selectedTab === 'form' ? 'grey' : '',
                    cursor: 'pointer'
                }}
                    onClick={() => { setSelectedTab('form') }}
                >
                    Client Form
                </div>
                <div style={{
                    border: '1px solid grey',
                    borderRadius: '20px',
                    padding: '5px 18px',
                    background: selectedTab === 'products' ? 'grey' : '',
                    cursor: 'pointer'
                }}
                    onClick={() => { setSelectedTab('products') }}
                > Products </div>
            </div>
            <div>
                {selectedTab === 'form' && (<div>
                    <ClientPage />
                </div>)}
                {selectedTab === 'products' && (<div>
                    <ProductAppPage />
                </div>)}
            </div>
        </div>
    )
}

export default SelectTab