import { Card, Text, LegacyCard, Tabs, Button, List } from "@shopify/polaris";
import classes from "./home.module.scss";
import { useCallback, useState } from "react";

export default function Home({
  currentPlan,
  goToProducts,
  goToAnalytics,
  goToContactUs,
  goToPlans,
  goToFaq,
  currentSessions,
}: any) {
  const tabs = [
    {
      id: "step1",
      content: "Products Chosen",
      component: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            height: "100%",
          }}
        >
          <Text as="h6" fontWeight="bold">
            Choose products to enable virtual try-on
          </Text>
          <Text tone="subdued" as="p" variant="bodySm">
            Manage your product selection and activate the ones you want for
            virtual try-on in your Shopify module.
          </Text>
          <div style={{ width: "fit-content" }}>
            <Button
              variant="primary"
              size="medium"
              tone="success"
              onClick={goToProducts}
            >
              Choose Product
            </Button>
          </div>
        </div>
      ),
    },
    {
      id: "step2",
      content: "Analytics",
      component: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            height: "100%",
          }}
        >
          <Text as="h6" fontWeight="bold">
            Gain insights into customer engagement with your business
          </Text>
          <Text tone="subdued" as="p" variant="bodySm">
            Leverage these insights to optimize your shop's performance and
            boost conversions.
          </Text>
          <div style={{ width: "fit-content" }}>
            <Button
              variant="primary"
              size="medium"
              tone="success"
              onClick={goToAnalytics}
            >
              View Report
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelectedTab(selectedTabIndex),
    []
  );

  return (
    <div className={classes.container}>
      <div className={classes.row1}>
        <div className={classes.section1}>
          <Card>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <Text as="h6" fontWeight="bold">
                Your Current Plan: {currentPlan?.name}
              </Text>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <List type="bullet" gap="extraTight">
                  {currentPlan?.description.map((item: any) => (
                    <List.Item>
                      <Text tone="subdued" as="p" variant="bodySm">
                        {item}
                      </Text>
                    </List.Item>
                  ))}
                </List>
              </div>
              {(currentPlan?.key === "free" || currentPlan?.key === "base") && (
                <div style={{ width: "fit-content" }}>
                  <Button
                    variant="primary"
                    size="medium"
                    tone="success"
                    onClick={goToPlans}
                  >
                    Upgrade Plan
                  </Button>
                </div>
              )}
            </div>
          </Card>
        </div>
        <div className={classes.section2}>
          <div className={classes.rowa}>
            <Card>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Text as="h6" fontWeight="bold">
                  Sessions
                </Text>
                <div>
                  <Text as="h4" tone="subdued">
                    {currentSessions} / {currentPlan?.totalSessions}
                  </Text>
                  <Text as="p" tone="subdued" variant="bodyLg">
                    sessions completed
                  </Text>
                </div>
              </div>
            </Card>
          </div>
          <div className={classes.rowb}>
            <Card>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Text as="h6" fontWeight="bold">
                  FAQ
                </Text>
                <div>
                  <Text as="p" tone="subdued">
                    For Step-by-Step guidelines, refer to FAQ section
                  </Text>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <LegacyCard>
        <Tabs
          tabs={tabs}
          selected={selectedTab}
          onSelect={handleTabChange}
          fitted
        >
          <LegacyCard.Section
            title={tabs[selectedTab].component}
          ></LegacyCard.Section>
        </Tabs>
      </LegacyCard>
      <Card>
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <Text as="h6" fontWeight="bold">
            Customer Support
          </Text>
          <Text tone="subdued" as="p" variant="bodySm">
            If you have questions about any of the features or run into
            technical issues, please don't hesitate to contact us. Get in touch
            with a member of the tech support team.
          </Text>
          <div style={{ width: "fit-content", marginLeft: "auto" }}>
            <Button
              variant="primary"
              size="medium"
              tone="success"
              onClick={goToContactUs}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
