import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/ClientForm.css';
import BasicDetails from '../components/clientFormSections/BasicDetails';
import Features from '../components/clientFormSections/Fetaures';
import ClientDetails from '../components/clientFormSections/ClientDetails';
import BrandGuideLines from '../components/clientFormSections/BrandGuideLines';
import MediaAssets from '../components/clientFormSections/MediaAssets';
import DownloadedImageDetails from '../components/clientFormSections/DownloadedImageDetails';
import AnalyticsDetails from '../components/clientFormSections/AnalyticsDetails';
import FiltersDetails, {
  Filter,
} from '../components/clientFormSections/FiltersDetails';
import { Spinner, Button, Modal } from 'react-bootstrap';
import PlatformDetails from '../components/clientFormSections/PlatformDetails';
import { getIsShopify } from '../components/GlobalVariables';

const ClientForm: React.FC<any> = ({ client }) => {
  const initialClient = {
    name: '',
    description: '',
    headers: {},
    ctaColor: '',
    brandColor: '',
    ...client,
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [clientData, setClientData] = useState(initialClient);
  const [files, setFiles] = useState<any>({});
  // const [ctaColor, setCtaColor] = useState<string>(
  //   clientData.ctaColor || '#000000',
  // );
  // const [brandColor, setBrandColor] = useState<string>(
  //   clientData.brandColor || '#000000',
  // );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showBasicDetails, setShowBasicDetails] = useState<boolean>(true);
  const [showFeaturesDetails, setShowFeaturesDetails] =
    useState<boolean>(false);
  const [showBrandGuideLines, setShowBrandGuideLines] =
    useState<boolean>(false);
  const [showClientDetails, setShowClientDetails] = useState<boolean>(false);
  const [showFiltersDetails, setShowFiltersDetails] = useState<boolean>(false);
  const [showMediaAssets, setShowMediaAssets] = useState<boolean>(false);
  const [showAnalyticsDetails, setShowAnalyticsDetails] =
    useState<boolean>(false);
  const [showPlatformDetails, setShowPlatformDetails] =
    useState<boolean>(false);
  const [hidePlatformDetails, setHidePlatformDetails] = useState(false)
  const [showDownloadedImageDetails, setShowDownloadedImageDetails] =
    useState<boolean>(false);
  const [selectedModes, setSelectedModes] = useState<any[]>(
    (client && client.modes) || [],
  );
  const [selectedCategories, setSelectedCategories] = useState<any[]>(
    (client && client.categories) || [],
  );
  const [subCategorySelections, setSubCategorySelections] = useState<any>(
    (client && client.subcategories) || {},
  );

  const [filters, setFilters] = useState<Filter[]>(clientData.filters || []);

  const [availableModes, setAvailableModes] = useState<any[]>([]);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const response = await fetch(
          'https://vto-api.metadome.ai/admin/subscription-tool',
        );
        if (!response.ok) {
          throw new Error('Failed to fetch tools');
        }
        const data = await response.json();
        setAvailableModes(data);
      } catch (err: any) {
        setError(err.message);
      }
    };
    fetchTools();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setLoading(true);

      const clientSelectedModes = selectedModes.map((mode) => mode.value);

      const clientSelectedCategories = Object.keys(subCategorySelections).map(
        (key) => {
          if (key.length === 0) return {};
          return {
            name: key,
            subCategories: subCategorySelections[key].map(
              (val: any) => val.value,
            ),
          };
        },
      );

      console.log(
        clientData,
        clientSelectedModes,
        clientSelectedCategories,
        filters,
        files,
      );

      const resClient = await fetch(`https://vto-api.metadome.ai/client`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(clientData),
      });

      const clientId = await resClient.text();

      if (clientSelectedModes && clientSelectedModes.length > 0) {
        await Promise.all(
          clientSelectedModes.map(async (mode: any) => {
            const resMode = await fetch(
              `https://vto-api.metadome.ai/client-subscription/${clientId}`,
              {
                method: 'POST',
                headers: {
                  'content-type': 'application/json',
                },
                body: JSON.stringify({
                  subscriptionTool: mode,
                  clientStatus: true,
                }),
              },
            );
            const modeId = await resMode.text();
            console.log(modeId);
          }),
        );
      }

      if (clientSelectedCategories && clientSelectedCategories.length > 0) {
        const resCategories = await fetch(
          `https://vto-api.metadome.ai/category/${clientId}`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({ data: clientSelectedCategories }),
          },
        );

        const categoriesAddedMessage = await resCategories.text();

        console.log(categoriesAddedMessage);
      }

      if (filters && filters.length > 0) {
        filters.forEach(async (filter) => {
          const resFilter = await fetch(
            `https://vto-api.metadome.ai/filter/${clientData.name}`,
            {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify(filter),
            },
          );
          const message = await resFilter.text();
          console.log(message);
        });
      }

      console.log(clientId);
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeModes = (selected: any[]) => {
    setSelectedModes(selected);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setClientData((prevState: any) => {
      if (name.startsWith('headers.')) {
        const headerKey = name.split('.')[1];
        return {
          ...prevState,
          headers: {
            ...prevState.headers,
            [headerKey]: type === 'checkbox' ? checked : value,
          },
        };
      } else {
        return {
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        };
      }
    });
  };

  // const handleCtaColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setCtaColor(value);
  //   setClientData((prevState: any) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleBrandColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setBrandColor(e.target.value);

  //   setClientData((prevState: any) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleFilterChange = (index: number, e: any) => {
    const { name, value, type, checked } = e.target;
    const updatedFilters = [...filters];
    updatedFilters[index] = {
      ...updatedFilters[index],
      [name]: type === 'checkbox' ? checked : value,
    };
    setFilters(updatedFilters);
  };

  const handleRangeInputChange = (
    filterIndex: number,
    rangeIndex: number,
    e: any,
  ) => {
    const { name, value } = e.target;
    const updatedFilters = [...filters];
    const updatedRanges = [...updatedFilters[filterIndex].ranges];
    updatedRanges[rangeIndex] = { ...updatedRanges[rangeIndex], [name]: value };
    updatedFilters[filterIndex] = {
      ...updatedFilters[filterIndex],
      ranges: updatedRanges,
    };
    setFilters(updatedFilters);
  };

  const addRangeInput = (filterIndex: number) => {
    const updatedFilters = [...filters];
    updatedFilters[filterIndex] = {
      ...updatedFilters[filterIndex],
      ranges: [
        ...updatedFilters[filterIndex].ranges,
        { min: '', max: '' },
      ] as any,
    };
    setFilters(updatedFilters);
  };

  const removeRangeInput = (filterIndex: number, rangeIndex: number) => {
    const updatedFilters = [...filters];
    const updatedRanges = [...updatedFilters[filterIndex].ranges];
    updatedRanges.splice(rangeIndex, 1);
    updatedFilters[filterIndex] = {
      ...updatedFilters[filterIndex],
      ranges: updatedRanges,
    };
    setFilters(updatedFilters);
  };

  const addFilter = () => {
    setFilters([
      ...filters,
      {
        name: '',
        level: '',
        type: '',
        dataType: '',
        key: '',
        l2key: '',
        isRangesAvailable: false,
        ranges: [],
      },
    ]);
  };

  const removeFilter = (index: number) => {
    const updatedFilters = [...filters];
    updatedFilters.splice(index, 1);
    setFilters(updatedFilters);
  };

  const acceptedFiles = {
    'image/jpeg': ['.jpg', '.jpeg'],
  };

  const onFileDrop = (name: string, acceptedFiles: any[]) => {
    console.log(acceptedFiles);
    setFiles((prevState: any) => ({
      ...prevState,
      [name]: acceptedFiles,
    }));
  };

  // const modes = ['model', 'image', 'live'];
  const modes = availableModes.map((mode) => mode.name);

  useEffect(() => {
    if (getIsShopify()) setHidePlatformDetails(true)
  }, [])

  return (
    <div className='container mt-3'>
      <h2>Client Form</h2>
      <div className='accordion' id='accordionParent'>
        <form>
          <BasicDetails
            client={clientData}
            handleChange={handleChange}
            showBasicDetails={showBasicDetails}
            setShowBasicDetails={setShowBasicDetails}
          />

          <PlatformDetails
            client={clientData}
            handleChange={handleChange}
            showPlatformDetails={showPlatformDetails}
            setShowPlatformDetails={setShowPlatformDetails}
            hidePlatformDetails={hidePlatformDetails}
          />

          <Features
            modes={modes}
            client={clientData}
            handleChange={handleChange}
            handleChangeModes={handleChangeModes}
            showFeaturesDetails={showFeaturesDetails}
            setShowFeaturesDetails={setShowFeaturesDetails}
            setSubCategorySelections={setSubCategorySelections}
            subCategorySelections={subCategorySelections}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />

          {!clientData.isFinalOutputSDK && (
            <>
              <ClientDetails
                client={clientData}
                handleChange={handleChange}
                setShowClientDetails={setShowClientDetails}
                showClientDetails={showClientDetails}
              />

              <FiltersDetails
                client={clientData}
                showFiltersDetails={showFiltersDetails}
                setShowFiltersDetails={setShowFiltersDetails}
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
                addFilter={addFilter}
                removeFilter={removeFilter}
                handleRangeInputChange={handleRangeInputChange}
                addRangeInput={addRangeInput}
                removeRangeInput={removeRangeInput}
              />

              <BrandGuideLines
                client={clientData}
                setShowBrandGuideLines={setShowBrandGuideLines}
                showBrandGuideLines={showBrandGuideLines}
                // ctaColor={ctaColor}
                // handleCtaColorChange={handleCtaColorChange}
                // brandColor={brandColor}
                // handleBrandColorChange={handleBrandColorChange}
                handleChange={handleChange}
              />

              <MediaAssets
                client={clientData}
                showMediaAssets={showMediaAssets}
                setShowMediaAssets={setShowMediaAssets}
                files={files}
                acceptedFiles={acceptedFiles}
                onFileDrop={onFileDrop}
              />

              <DownloadedImageDetails
                client={clientData}
                showDownloadedImageDetails={showDownloadedImageDetails}
                setShowDownloadedImageDetails={setShowDownloadedImageDetails}
                handleChange={handleChange}
                files={files}
                acceptedFiles={acceptedFiles}
                onFileDrop={onFileDrop}
              />

              <AnalyticsDetails
                client={clientData}
                showAnalyticsDetails={showAnalyticsDetails}
                setShowAnalyticsDetails={setShowAnalyticsDetails}
                handleChange={handleChange}
              />
            </>
          )}

          <Button
            type='submit'
            className='btn btn-primary my-3 d-flex ml-auto'
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
                <span className='sr-only'>Loading...</span>
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </form>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientForm;
