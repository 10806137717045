import CustomTextInput from '../TextInput';
import CustomUploader from '../Uploader';
import React from 'react';

const DownloadedImageDetails = (props: any) => {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='downloadedImageDetails'>
        <button
          className={
            'accordion-button' +
            (props.showDownloadedImageDetails ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseDownloadedImageDetails'
          aria-expanded={props.showDownloadedImageDetails ? 'true' : 'false'}
          aria-controls='collapseDownloadedImageDetails'
          onClick={(e) => {
            e.preventDefault();
            props.setShowDownloadedImageDetails(
              (showDownloadedImageDetails: boolean) =>
                !showDownloadedImageDetails,
            );
          }}
        >
          Downloaded Image Details
        </button>
      </h2>
      <div
        id='collapseDownloadedImageDetails'
        className={`accordion-collapse collapse ${
          props.showDownloadedImageDetails ? 'show' : ''
        }`}
        aria-labelledby='downloadedImageDetails'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='watermarkName'
                label='Watermark Name'
                type='text'
                id='watermarkName'
                name='watermarkName'
                value={props.client.watermarkName}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='watermarkLink'
                label='Watermark Link'
                type='text'
                id='watermarkLink'
                name='watermarkLink'
                value={props.client.watermarkLink}
                onChange={props.handleChange}
              />
            </div>
          </div>

          <div className='form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              id='showWaterMark'
              name='showWaterMark'
              checked={props.client.showWaterMark}
              onChange={props.handleChange}
            />
            <label className='form-check-label' htmlFor='showWaterMark'>
              Show Water Mark
            </label>
          </div>

          {props.client.showWaterMark && (
            <div className='mt-1'>
              <CustomUploader
                files={props.files['waterMark']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('waterMark', acceptedFiles);
                }}
                label={'Watermark Logo'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadedImageDetails;
