import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const ClientsListPage: React.FC = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch('https://vto-api.metadome.ai/client', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')} ${localStorage.getItem('refresh_token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        await Promise.all(
          data.map(async (client: any) => {
            const modeResponse = await fetch(
              `https://vto-api.metadome.ai/client-subscription/${client.Id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('access_token')} ${localStorage.getItem('refresh_token')}`,
                },
              },
            ).then((res) => res.json());

            const categoryResponse = await fetch(
              `https://vto-api.metadome.ai/category/${client.Id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('access_token')} ${localStorage.getItem('refresh_token')}`,
                },
              },
            ).then((res) => res.json());

            const filtersResponse = await fetch(
              `https://vto-api.metadome.ai/filter/${client.name}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('access_token')} ${localStorage.getItem('refresh_token')}`,
                },
              },
            ).then((res) => res.json());

            client.modes = modeResponse.map((mode: any) => {
              return {
                value: mode.subscriptionTool.name,
                label: mode.subscriptionTool.name,
              };
            });
            client.categories = categoryResponse.map((category: any) => {
              return {
                value: category.name,
                label: category.name,
              };
            });
            client.subcategories = {};
            categoryResponse.map((category: any) => {
              client.subcategories[category.name] = category.subCategories.map(
                (subCategory: any) => {
                  return {
                    value: subCategory.name,
                    label: subCategory.name,
                  };
                },
              );
            });

            console.log(client.subcategories);
            client.filters = filtersResponse;

            console.log(client);
          }),
        );
        setClients(data);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='container mt-5'>
      <div className='mb-4'>
        <h3>Clients</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Platform</th>
            </tr>
          </thead>
          <tbody>
            {clients.length === 0 && (
              <tr>
                <td colSpan={3}>No clients found</td>
              </tr>
            )}
            {clients.map((client) => (
              <tr
                key={client.Id}
                onClick={() => {
                  navigate('/admin/details/client/form', {
                    state: { client: client },
                  });
                }}
              >
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td>{client.platformId}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ClientsListPage;
