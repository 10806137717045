import React from 'react';

const CustomTextInput = (props: any) => {
  const formatDateToInputValue = (date: string) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div className='mb-3'>
      <label htmlFor={props.htmlFor} className='form-label'>
        {props.label}
      </label>
      <input
        type={props.type}
        className='form-control'
        id={props.id}
        name={props.name}
        value={
          props.type === 'date'
            ? formatDateToInputValue(props.value)
            : props.value
        }
        onChange={props.onChange}
      />
    </div>
  );
};

export default CustomTextInput;
