import React, { useState } from 'react';
import Creatable from 'react-select/creatable';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';

import {
  addCategories,
  addSubCategories,
} from '../store/slices/categoriesSlice';

interface Category {
  [key: string]: string[];
}

const Categories = (props: any) => {
  const categoriesData = useSelector((state: any) => state.categories);
  const dispatch = useDispatch();

  const [categories, setCategories] = useState<Category>({
    eyes: ['eyeliner', 'eyeshadow', 'mascara'],
    lips: ['lipstick', 'lipliner'],
    face: ['foundation', 'blush'],
  });

  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [showSubCategoryModal, setShowSubCategoryModal] =
    useState<boolean>(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [newSubCategoryName, setNewSubCategoryName] = useState<string>('');
  const [currentCategory, setCurrentCategory] = useState<any>('');

  const handleChangeCategories = (selected: any[]) => {
    props.setSelectedCategories(selected);
    const newSubCategorySelections: any = {};
    selected.forEach((category) => {
      const categoryValue = category.value;
      newSubCategorySelections[categoryValue] =
        props.subCategorySelections[categoryValue] || [];
    });
    props.setSubCategorySelections(newSubCategorySelections);

    dispatch(addCategories(props.selectedCategories));
    dispatch(addSubCategories(props.subCategorySelections));
  };

  const handleChangeSubCategories = (selected: any[], category: any) => {
    props.setSubCategorySelections({
      ...props.subCategorySelections,
      [category.value]: selected || [],
    });

    dispatch(addSubCategories(props.subCategorySelections));
  };

  const handleCreateCategory = () => {
    setShowCategoryModal(true);
  };

  const handleCreateSubCategory = (category: any) => {
    setCurrentCategory(category);
    setShowSubCategoryModal(true);
  };

  const saveNewCategory = (e: React.FormEvent) => {
    e.preventDefault();
    const newCategory = newCategoryName;
    setCategories({
      ...categories,
      [newCategory]: [],
    });
    props.setSelectedCategories([
      ...props.selectedCategories,
      { value: newCategory, label: newCategory },
    ]);

    dispatch(addCategories(props.selectedCategories));
    dispatch(addSubCategories(props.subCategorySelections));
    setNewCategoryName('');
    setShowCategoryModal(false);
  };

  const saveNewSubCategory = (e: React.FormEvent) => {
    e.preventDefault();
    const newSubCategory = newSubCategoryName;
    const updatedCategory = categories[currentCategory.label] || [];
    updatedCategory.push(newSubCategory);
    setCategories({
      ...categories,
      [currentCategory.label]: updatedCategory,
    });
    props.setSubCategorySelections({
      ...props.subCategorySelections,
      [currentCategory.value]: [
        ...(props.subCategorySelections[currentCategory.value] || []),
        { value: newSubCategory, label: newSubCategory },
      ],
    });
    dispatch(addCategories(props.selectedCategories));
    dispatch(addSubCategories(props.subCategorySelections));
    setNewSubCategoryName('');
    setShowSubCategoryModal(false);
  };

  const handleCategoryInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    setNewCategoryName(inputValue);
    return inputValue;
  };

  const handleSubCategoryInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    setNewSubCategoryName(inputValue);
    return inputValue;
  };

  const createCategoryOption = (inputValue: string) => {
    setNewCategoryName(inputValue);
    return {
      value: inputValue.toLowerCase(),
      label: `Create "${inputValue}"`,
    };
  };

  const createSubCategoryOption = (inputValue: string) => {
    setNewSubCategoryName(inputValue);
    return {
      value: inputValue.toLowerCase(),
      label: `Create "${inputValue}"`,
    };
  };

  return (
    <>
      <div className='mb-3'>
        <label htmlFor='categories' className='form-label'>
          Categories
        </label>
        <Creatable
          isMulti
          options={Object.keys(categories).map((category) => ({
            value: category,
            label: category,
          }))}
          value={props.selectedCategories}
          onChange={handleChangeCategories as any}
          onCreateOption={handleCreateCategory}
          formatCreateLabel={(inputValue: string) =>
            createCategoryOption(inputValue).label
          }
        />
      </div>

      {props.selectedCategories.map(
        (category: { value: string; label: string }) => (
          <div className='mb-3' key={category.label}>
            <label
              htmlFor={`subcategories-${category.label}`}
              className='form-label'
            >
              Sub Categories for {category.label}
            </label>
            <Creatable
              isMulti
              options={categories[category.label].map((subCategory) => ({
                value: subCategory,
                label: subCategory,
              }))}
              value={props.subCategorySelections[category.value]}
              onChange={(selected: any) =>
                handleChangeSubCategories(selected, category) as any
              }
              onCreateOption={() => handleCreateSubCategory(category)}
              formatCreateLabel={(inputValue: string) =>
                createSubCategoryOption(inputValue).label
              }
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </div>
        ),
      )}

      <Modal
        show={showCategoryModal}
        onHide={() => setShowCategoryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='formNewCategory'>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter new category name'
                value={newCategoryName}
                onChange={(e) => handleCategoryInputChange(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setShowCategoryModal(false)}
          >
            Close
          </Button>
          <Button
            variant='primary'
            onClick={saveNewCategory}
            disabled={!newCategoryName.trim()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSubCategoryModal}
        onHide={() => setShowSubCategoryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Subcategory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='formNewSubCategory'>
              <Form.Label>Subcategory Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter new subcategory name'
                value={newSubCategoryName}
                onChange={(e) => handleSubCategoryInputChange(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setShowSubCategoryModal(false)}
          >
            Close
          </Button>
          <Button
            variant='primary'
            onClick={saveNewSubCategory}
            disabled={!newSubCategoryName.trim()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Categories;
