import {
  Button,
  Toast,
  Text,
  Tag,
  Card,
  Autocomplete,
  Icon,
  IndexTable,
  Popover,
  LegacyCard,
  useIndexResourceState,
  Select,
  TextField,
  Modal,
  Frame,
  IndexTableSelectionType,
  OptionList,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import classes from "./productInegration.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Product, Variant } from "./try";

const options = [
  { label: "Lipstick", value: "Lipstick" },
  { label: "Lipliner", value: "Lipliner" },
  { label: "Eyeshadow", value: "Eyeshadow" },
  { label: "Eyeliner", value: "Eyeliner" },
  { label: "Foundation", value: "Foundation" },
  { label: "Blush", value: "Blush" },
];

export default function ProductIntegration({
  allProducts,
  ShopifyProducts,
  LocalProducts,
  setLocalProducts,
  saveProductInfo,
  deleteProduct,
  addProduct,
  selectedCategoriesRes,
  postSelectedCategories,
  currentPlan,
  setSelectedCategoriesRes,
  paginationInfo,
  setPageCursor,
  setApiSearchVal,
  setPaginationInfo,
  apiSearchVal,
}: any) {
  const addedProductsData = LocalProducts || [];
  const [chooseProdDisabled, setChooseProdDisabled] = useState(true);
  const [showSaveProductButton, setShowSaveProductButton] = useState(false);
  const [searchVal, setSearcVal] = useState("");
  const [chooseProductsModalOpen, setChooseProductsModalOpen] = useState(false);
  const productsToBeRemoved = useRef<any>([]);
  const productsToBeSaved = useRef<any>([]);
  const productsToBeAdded = useRef<any>([]);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(addedProductsData);
  const [showToast, setShowToast] = useState(false);
  const [selectCats, setSelectCatss] = useState<string[]>([]);
  const [showProdSaveNot, setShowProdSavedNot] = useState(false);
  useEffect(() => {
    if (
      selectedCategoriesRes &&
      selectedCategoriesRes?.length === currentPlan?.maxCategories
    )
      setChooseProdDisabled(false);
    else setChooseProdDisabled(true);
  }, [selectedCategoriesRes, currentPlan]);

  const rowMarkup = !chooseProdDisabled ? (
    addedProductsData
      ?.filter((val: Product) =>
        searchVal
          ? val.productName.toLowerCase().includes(searchVal.toLowerCase())
          : val
      )
      ?.map(
        (
          { imageUrl, productName, productId, makeup, variants }: Product,
          index: number
        ) => {
          return (
            <>
              <IndexTable.Row
                id={productId}
                key={productId}
                selected={selectedResources.includes(productId)}
                position={index}
                onClick={() => {}}
              >
                <IndexTable.Cell>
                  <img
                    loading="lazy"
                    src={imageUrl}
                    style={{
                      width: "35px",
                      height: "35px",
                      marginRight: "10px",
                    }}
                    alt=""
                  />
                  <Text variant="bodyMd" as="span">
                    {productName}
                  </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Select
                    label=""
                    options={selectedCategoriesRes || []}
                    onChange={(val) => {
                      const tempLocalProducts = [...LocalProducts];
                      const i = tempLocalProducts.findIndex(
                        (prod) => prod.productId === productId
                      );
                      tempLocalProducts[i].makeup = val;
                      let isProdAlreadyChanged = false;
                      for (const prod of productsToBeSaved.current)
                        if (prod === productId) isProdAlreadyChanged = true;
                      if (!isProdAlreadyChanged)
                        productsToBeSaved.current.push(productId);
                      setLocalProducts(tempLocalProducts);
                      setShowSaveProductButton(true);
                    }}
                    value={
                      options.find(
                        (val) =>
                          val.label.toLowerCase() === makeup.toLowerCase()
                      )?.value || ""
                    }
                  />
                </IndexTable.Cell>
              </IndexTable.Row>
              {variants?.map(
                ({ variantId, variantName, color }: Variant, rowIndex) => (
                  <IndexTable.Row
                    rowType="child"
                    key={variantId}
                    id={variantId}
                    position={index}
                    hideSelectable={true}
                  >
                    <IndexTable.Cell>
                      <Text variant="bodyXs" as="span">
                        {variantName}
                      </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text variant="bodyXs" as="span">
                        {makeup.toUpperCase()}
                      </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: color,
                            width: "20px",
                            height: "20px",
                          }}
                        ></div>
                        <TextField
                          label=""
                          value={color}
                          onChange={(val: string) => {
                            let tempLocalProducts = [...LocalProducts];
                            const i = tempLocalProducts.findIndex(
                              (prod) => prod.productId === productId
                            );
                            tempLocalProducts[i].variants[rowIndex] = {
                              ...tempLocalProducts[index].variants[rowIndex],
                              color: val,
                            };
                            setLocalProducts(tempLocalProducts);
                            setShowSaveProductButton(true);
                            let isProdAlreadyChanged = false;
                            for (const prod of productsToBeSaved.current)
                              if (prod === productId)
                                isProdAlreadyChanged = true;
                            if (!isProdAlreadyChanged)
                              productsToBeSaved.current.push(productId);
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                )
              )}
            </>
          );
        }
      )
  ) : (
    <></>
  );

  const updateSearchVal = useCallback(
    (value: string) => setSearcVal(value),
    []
  );

  const searchBar = (
    <Autocomplete.TextField
      onChange={updateSearchVal}
      label=""
      value={searchVal}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Filter Products"
      autoComplete="off"
    />
  );

  useEffect(() => {
    if (LocalProducts.length === 0) {
      productsToBeSaved.current = [];
      setShowSaveProductButton(false);
    }
  }, [LocalProducts]);

  const ChooseProducts = () => {
    const [chooseProductsSearchVal, setChooseProductsSearchVal] =
      useState(apiSearchVal);
    const productsData = ShopifyProducts;
    const { selectedResources, allResourcesSelected, handleSelectionChange } =
      useIndexResourceState(productsData);
    const updateChooseProductsSearchVal = useCallback((value: string) => {
      if (!value) {
        setPaginationInfo({});
        setPageCursor({ cursor: "", step: "" });
        setApiSearchVal("");
      }
      setChooseProductsSearchVal(value);
    }, []);
    useEffect(() => {
      productsToBeAdded.current = selectedResources;
    }, [selectedResources]);
    const [showProdAlreadyAdded, setShowProdAlreadyAdded] = useState(false);

    const NotifyProdAlreadyAdded = showProdAlreadyAdded ? (
      <Toast
        content={` Product Already Added!`}
        onDismiss={() => {
          setShowProdAlreadyAdded(false);
        }}
      />
    ) : null;

    const LocalProductIds = LocalProducts?.map(
      (localproduct: Product) => localproduct.productId
    );
    const rowMarkup = allProducts?.map(
      (
        { imageUrl, productName, productId, makeup }: Product,
        index: number
      ) => (
        <>
          <IndexTable.Row
            id={productId}
            key={productId}
            selected={selectedResources.includes(productId)}
            position={index}
            onClick={() => {
              if (LocalProductIds.includes(productId)) {
                setShowProdAlreadyAdded(true);
                setTimeout(() => {
                  setShowProdAlreadyAdded(false);
                }, 3000);
              }
            }}
            disabled={LocalProductIds.includes(productId)}
          >
            <IndexTable.Cell>
              <img
                loading="lazy"
                src={imageUrl}
                style={{ width: "35px", height: "35px", marginRight: "10px" }}
                alt=""
              />
              <Text variant="bodyMd" as="span">
                {productName}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" as="span">
                {makeup.toUpperCase()}
              </Text>
            </IndexTable.Cell>
          </IndexTable.Row>
        </>
      )
    );

    const chooseProductsSearchBar = (
      <Autocomplete.TextField
        onChange={updateChooseProductsSearchVal}
        label=""
        value={chooseProductsSearchVal}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search Products"
        autoComplete="off"
      />
    );

    return (
      <div>
        <Frame>
          {NotifyProdAlreadyAdded}
          <Modal
            open={chooseProductsModalOpen}
            onClose={() => setChooseProductsModalOpen(false)}
            title="Add Products"
            primaryAction={{
              content: "Add",
              onAction: () => {
                addProduct(productsToBeAdded.current);
                productsToBeSaved.current = [
                  ...productsToBeSaved.current,
                  ...productsToBeAdded.current,
                ];
                productsToBeAdded.current = [];
                setShowSaveProductButton(true);
                setChooseProductsModalOpen(false);
              },
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: () => {
                  setChooseProductsModalOpen(false);
                  productsToBeAdded.current = [];
                },
              },
            ]}
          >
            <Modal.Section>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "80%" }}>
                    <Autocomplete
                      options={[]}
                      selected={[]}
                      onSelect={() => {}}
                      textField={chooseProductsSearchBar}
                    />
                  </div>
                  <Button
                    tone="success"
                    size="medium"
                    variant="primary"
                    onClick={() => {
                      setPaginationInfo({});
                      setPageCursor({ cursor: "", step: "" });
                      setApiSearchVal(chooseProductsSearchVal);
                    }}
                  >
                    Search
                  </Button>
                </div>
                <LegacyCard>
                  <IndexTable
                    key={"products"}
                    itemCount={allProducts.length}
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={[{ title: "Product" }, { title: "Category" }]}
                    pagination={{
                      hasNext: paginationInfo?.hasNextPage || false,
                      hasPrevious: paginationInfo?.hasPreviousPage || false,
                      onNext: () =>
                        setPageCursor({
                          cursor: paginationInfo?.endCursor,
                          step: "next",
                        }),
                      onPrevious: () =>
                        setPageCursor({
                          cursor: paginationInfo?.startCursor,
                          step: "previous",
                        }),
                    }}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard>
              </div>
            </Modal.Section>
          </Modal>
        </Frame>
      </div>
    );
  };

  useEffect(() => {
    productsToBeRemoved.current = selectedResources;
  }, [selectedResources]);

  // const removeTag = useCallback(
  //     (tag: string) => () => {
  //         // const option: any = options.find((val) => val.value === tag)

  //         setCatOption((prev) => [...prev, { value: tag, label: tag }])
  //         setSelectCatss((previousTags) =>
  //             previousTags.filter((previousTag) => previousTag !== tag),
  //         );
  //     },
  //     [],
  // );

  const [popoverActive, setPopoverActive] = useState(true);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} fullWidth={true} disclosure>
      Categories
    </Button>
  );

  const Notify = showToast ? (
    <Toast
      content={`Please select ${currentPlan?.maxCategories} categories to proceed.`}
      onDismiss={() => {
        setShowToast(false);
      }}
    />
  ) : null;

  const NotifySaveProd = showProdSaveNot ? (
    <Toast
      content={` Products saved!`}
      onDismiss={() => {
        setShowProdSavedNot(false);
      }}
    />
  ) : null;

  return (
    <Frame>
      <div className={classes.container}>
        {Notify}
        {NotifySaveProd}
        {chooseProdDisabled && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text as="h6">Select Categories</Text>
              <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
              >
                <OptionList
                  title="Makeup Categories"
                  onChange={setSelectCatss}
                  options={options}
                  selected={selectCats}
                  allowMultiple
                />
              </Popover>
            </div>
            {/* <div style={{ display: 'flex', gap: '10px', width: '70%', marginLeft: 'auto', marginBottom: '10px' }}>
                        {selectCats.map((val) => <Card padding={'200'} key={val}><Tag onRemove={removeTag(val)}>{val}</Tag></Card>)}
                    </div> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text as="p" tone="subdued" variant="bodyMd">
                You must select exactly {`${currentPlan?.maxCategories}`}{" "}
                categories before you can add Products
              </Text>
              <Button
                tone="success"
                size="medium"
                variant="primary"
                onClick={() => {
                  if (selectCats.length !== currentPlan.maxCategories) {
                    setShowToast(true);
                    setTimeout(() => {
                      setShowToast(false);
                    }, 5000);
                    return;
                  }
                  postSelectedCategories(selectCats);
                  setChooseProdDisabled(false);
                  setSelectedCategoriesRes(selectCats);
                }}
              >
                Save Categories
              </Button>
            </div>
          </div>
        )}
        <div className={classes.header}>
          <div className={classes.headerRow}>
            <Text as="h3" fontWeight="bold">
              Product Integration
            </Text>
            <Button
              tone="success"
              size="medium"
              variant="primary"
              disabled={chooseProdDisabled}
              onClick={() => setChooseProductsModalOpen(true)}
            >
              Choose Product
            </Button>
          </div>
          {!chooseProdDisabled && (
            <div className={classes.headerRow}>
              <div style={{ display: "flex", gap: "15px" }}>
                <Tag>{addedProductsData.length} SKUs Integrated</Tag>
              </div>
            </div>
          )}
        </div>
        <div>
          {
            <Card>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "88%" }}>
                    <Autocomplete
                      options={[]}
                      selected={[]}
                      onSelect={() => {}}
                      textField={searchBar}
                    />
                  </div>
                  <div
                    style={{
                      width: "11%",
                      display: "flex",
                      justifyContent: "flex-end",
                      visibility: showSaveProductButton ? "visible" : "hidden",
                    }}
                  >
                    <Button
                      tone="success"
                      size="medium"
                      variant="primary"
                      onClick={() => {
                        saveProductInfo(productsToBeSaved.current);
                        productsToBeSaved.current = [];
                        setShowSaveProductButton(false);
                        setShowProdSavedNot(true);
                        setTimeout(() => {
                          setShowProdSavedNot(false);
                        }, 2000);
                      }}
                    >
                      Save Products
                    </Button>
                  </div>
                </div>
                <LegacyCard>
                  <IndexTable
                    key={"local"}
                    itemCount={
                      !chooseProdDisabled ? addedProductsData.length : 0
                    }
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedResources.length
                    }
                    onSelectionChange={(...args) => {
                      handleSelectionChange(...args);
                    }}
                    headings={[
                      { title: "Product" },
                      { title: "Category" },
                      { title: "Shades" },
                    ]}
                    promotedBulkActions={[
                      {
                        content: "Remove Products",
                        onAction: () => {
                          deleteProduct(productsToBeRemoved.current);
                          for (const prod of productsToBeRemoved.current) {
                            productsToBeSaved.current =
                              productsToBeSaved.current.filter(
                                (val: any) => val !== prod
                              );
                            handleSelectionChange(
                              IndexTableSelectionType.Single,
                              false,
                              prod
                            );
                          }
                          productsToBeRemoved.current = [];
                        },
                      },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard>
              </div>
            </Card>
          }
        </div>
        {chooseProductsModalOpen && <ChooseProducts />}
      </div>
    </Frame>
  );
}
