import React, { useCallback } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';

interface DropzoneProps extends DropzoneOptions {
  onFileDrop: (acceptedFiles: File[]) => void;
  label: string;
  files: File[];
  acceptedFiles: { [key: string]: string[] };
}

const CustomUploader: React.FC<DropzoneProps> = ({ onFileDrop, ...props }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileDrop(acceptedFiles);
    },
    [onFileDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...props,
  });

  return (
    <div className='mb-3'>
      <label className='form-label'>{props.label}</label>
      <div
        {...getRootProps({ className: 'dropzone' })}
        className='dropzone border p-4 text-center'
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <div className='mt-2 file-list'>
        {props.files &&
          props.files.map((file: any) => (
            <div key={file.path} className='file-item'>
              {file.path} - {file.size} bytes
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomUploader;
