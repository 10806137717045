import CustomUploader from '../Uploader';
import React from 'react';

const MediaAssets = (props: any) => {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='mediaAssets'>
        <button
          className={
            'accordion-button' + (props.showMediaAssets ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseMediaAssets'
          aria-expanded={props.showMediaAssets ? 'true' : 'false'}
          aria-controls='collapseMediaAssets'
          onClick={(e) => {
            e.preventDefault();
            props.setShowMediaAssets(
              (showMediaAssets: boolean) => !showMediaAssets,
            );
          }}
        >
          Media Assets
        </button>
      </h2>
      <div
        id='collapseMediaAssets'
        className={`accordion-collapse collapse ${
          props.showMediaAssets ? 'show' : ''
        }`}
        aria-labelledby='mediaAssets'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-md-4'>
              <CustomUploader
                files={props.files['logo']}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('logo', acceptedFiles);
                }}
                label={'Logo'}
                acceptedFiles={props.acceptedFiles}
              />
            </div>
            <div className='col-md-4'>
              <CustomUploader
                files={props.files['bgImage']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('bgImage', acceptedFiles);
                }}
                label={'bgImage'}
              />
            </div>
            <div className='col-md-4'>
              <CustomUploader
                files={props.files['ffinitalGif']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('ffinitalGif', acceptedFiles);
                }}
                label={'ffinitalGif'}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <CustomUploader
                files={props.files['imgTryOnInit1']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('imgTryOnInit1', acceptedFiles);
                }}
                label={'imgTryOnInit1'}
              />
            </div>
            <div className='col-md-6'>
              <CustomUploader
                files={props.files['imgTryOnInit2']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('imgTryOnInit2', acceptedFiles);
                }}
                label={'imgTryOnInit2'}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <CustomUploader
                files={props.files['loadingGif1']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('loadingGif1', acceptedFiles);
                }}
                label={'loadingGif1'}
              />
            </div>
            <div className='col-md-4'>
              <CustomUploader
                files={props.files['loadingGif2']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('loadingGif2', acceptedFiles);
                }}
                label={'loadingGif2'}
              />
            </div>
            <div className='col-md-4'>
              <CustomUploader
                files={props.files['loadingGif3']}
                acceptedFiles={props.acceptedFiles}
                onFileDrop={(acceptedFiles: any) => {
                  props.onFileDrop('loadingGif3', acceptedFiles);
                }}
                label={'loadingGif3'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaAssets;
