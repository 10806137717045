import React from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminLogin from "./pages/adminLoginPage";
import ClientSubscriptions from "./pages/clientSubscriptionsPage";
import AdminDashBoardPage from "./pages/adminDashBoardPage";
import ClientsListPage from "./pages/ClientsListPage";
import SubscriptionToolsListPage from "./pages/SubscriptionToolsListPage";
import AdminClientPage from "./pages/adminClientPage";
import AdminClientDetailsPage from "./pages/adminClientFormPage";
import ProductAppPage from "./pages/ProductAppPage";
import { useUrlParams } from "./components/useUrlParams";
import SelectTab from "./pages/selectTab";
import Dashboard from "./views/try";

const App: React.FC = () => {
  const params = useUrlParams();
  return (
    <Router>
      <Routes>
        <Route
          path="/new"
          element={
            <>
              <Dashboard />
            </>
          }
        />
        <Route
          path="/shopifyApp"
          element={
            <>
              <ProductAppPage />
            </>
          }
        />

        <Route
          path="/admin"
          element={
            <>
              <AdminLogin />
            </>
          }
        />

        <Route
          path="/admin/dashboard"
          element={
            <>
              <AdminDashBoardPage />
            </>
          }
        >
          <Route index element={<Navigate to="clientsList" replace />} />
          <Route
            path="clientsList"
            element={
              <>
                <ClientsListPage />
              </>
            }
          />
          <Route
            path="subscriptionTools"
            element={
              <>
                <SubscriptionToolsListPage />
              </>
            }
          />
        </Route>

        <Route path="/admin/details">
          <Route index element={<Navigate to="client" replace />} />
          <Route
            path="client"
            element={
              <>
                <AdminClientPage />
              </>
            }
          >
            <Route
              path="form"
              element={
                <>
                  <AdminClientDetailsPage />
                </>
              }
            />
          </Route>
          <Route
            path="subscriptions"
            element={
              <>
                <ClientSubscriptions />
              </>
            }
          />
        </Route>

        <Route
          path="/selector"
          element={
            <>
              <SelectTab />
            </>
          }
        />

        <Route path="*" element={<Navigate to="new" />} />
      </Routes>
    </Router>
  );
};

export default App;
