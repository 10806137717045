import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SubCategorySelections {
  [key: string]: any;
}

export interface CategoriesState {
  subCategorySelections: SubCategorySelections;
  selectedCategories: any[];
}

const initialState: CategoriesState = {
  subCategorySelections: {},
  selectedCategories: [],
};

const categoriesSlice = createSlice({
  name: 'Categories',
  initialState,
  reducers: {
    addCategories: (state, action: PayloadAction<any>) => {
      state.selectedCategories.push(action.payload);
    },
    addSubCategories: (state, action: PayloadAction<SubCategorySelections>) => {
      state.subCategorySelections = action.payload;
    },
  },
});

export const { addSubCategories, addCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
