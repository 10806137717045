import React from 'react';

const CustomColorInput = (props: any) => {
  return (
    <div className='mb-3'>
      <label htmlFor='ctoColor' className='form-label'>
        {props.label}
      </label>
      <div className='flex-row-reverse d-flex'>
        <div className='col-md-10 p-0 ml-1'>
          <input
            type='text'
            className='form-control'
            id={props.name}
            name={props.name}
            value={props.color}
            onChange={props.handleChange}
            placeholder='Enter hex color code'
          />
        </div>
        <div className='col-md-2 p-0'>
          <input
            type='color'
            className='form-control form-control-color'
            id={props.name}
            name={props.name}
            value={props.color}
            onChange={props.handleChange}
            title='Choose your color'
          />
        </div>
      </div>
    </div>
  );
};

export default CustomColorInput;
