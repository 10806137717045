import CustomColorInput from '../ColorInput';
import CustomTextInput from '../TextInput';
import React from 'react';

const BrandGuideLines = (props: any) => {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='brandGuideLines'>
        <button
          className={
            'accordion-button' + (props.showBrandGuideLines ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseBrandGuideLines'
          aria-expanded={props.showBrandGuideLines ? 'true' : 'false'}
          aria-controls='collapseBrandGuideLines'
          onClick={(e) => {
            e.preventDefault();
            props.setShowBrandGuideLines(
              (showBrandGuideLines: boolean) => !showBrandGuideLines,
            );
          }}
        >
          Brand Guidelines
        </button>
      </h2>
      <div
        id='collapseBrandGuideLines'
        className={`accordion-collapse collapse ${
          props.showBrandGuideLines ? 'show' : ''
        }`}
        aria-labelledby='brandGuideLines'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-md-6'>
              <CustomColorInput
                color={props.client.ctaColor}
                handleChange={props.handleChange}
                label={'Cta Color'}
                name={'ctaColor'}
              />
            </div>
            <div className='col-md-6'>
              <CustomColorInput
                color={props.client.brandColor}
                handleChange={props.handleChange}
                label={'Brand Color'}
                name={'brandColor'}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='fontName'
                label='Font Name'
                type='text'
                id='fontName'
                name='fontName'
                value={props.client.fontName}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='fontLink'
                label='Font Link'
                type='text'
                id='fontLink'
                name='fontLink'
                value={props.client.fontLink}
                onChange={props.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandGuideLines;
