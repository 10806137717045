import React from 'react';
import { BsTrash } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface Filter {
  name: string;
  level: string;
  type: string;
  dataType: string;
  key: string;
  l2key: string;
  isRangesAvailable: boolean;
  ranges: Range[];
}

const FiltersDetails = (props: any) => {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='filtersDetails'>
        <button
          className={
            'accordion-button' + (props.showFiltersDetails ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseFiltersDetails'
          aria-expanded={props.showFiltersDetails ? 'true' : 'false'}
          aria-controls='collapseFiltersDetails'
          onClick={(e) => {
            e.preventDefault();
            props.setShowFiltersDetails(
              (showFiltersDetails: boolean) => !showFiltersDetails,
            );
          }}
        >
          Filters Details
        </button>
      </h2>
      <div
        id='collapseFiltersDetails'
        className={`accordion-collapse collapse ${
          props.showFiltersDetails ? 'show' : ''
        }`}
        aria-labelledby='filtersDetails'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='container mx-auto'>
            {props.filters.map((filter: Filter, filterIndex: number) => (
              <div key={filterIndex} className='filter-section mb-4'>
                <h4>{filter.name} Filter Details</h4>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label
                        htmlFor={`name-${filterIndex}`}
                        className='form-label'
                      >
                        Name
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id={`name-${filterIndex}`}
                        name='name'
                        value={filter.name}
                        onChange={(e) =>
                          props.handleFilterChange(filterIndex, e)
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label
                        htmlFor={`level-${filterIndex}`}
                        className='form-label'
                      >
                        Level
                      </label>
                      <select
                        className='form-control'
                        id={`level-${filterIndex}`}
                        name='level'
                        value={filter.level}
                        onChange={(e) =>
                          props.handleFilterChange(filterIndex, e)
                        }
                      >
                        <option value=''>Select Level</option>
                        <option value='productLevel'>Product</option>
                        <option value='variantLevel'>Variant</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label
                        htmlFor={`type-${filterIndex}`}
                        className='form-label'
                      >
                        Type
                      </label>
                      <select
                        className='form-control'
                        id={`type-${filterIndex}`}
                        name='type'
                        value={filter.type}
                        onChange={(e) =>
                          props.handleFilterChange(filterIndex, e)
                        }
                      >
                        <option value=''>Select Type</option>
                        <option value='tag'>Tag</option>
                        <option value='meta'>Meta</option>
                        <option value='self'>Self</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label
                        htmlFor={`dataType-${filterIndex}`}
                        className='form-label'
                      >
                        Data Type
                      </label>
                      <select
                        className='form-control'
                        id={`dataType-${filterIndex}`}
                        name='dataType'
                        value={filter.dataType}
                        onChange={(e) =>
                          props.handleFilterChange(filterIndex, e)
                        }
                      >
                        <option value=''>Select Data Type</option>
                        <option value='intNumber'>Int</option>
                        <option value='floatNumber'>Float</option>
                        <option value='string'>String</option>
                        <option value='ArrayOfString'>Array Of Strings</option>
                        <option value='ArrayOfNumber'>Array Of Numbers</option>
                        <option value='dateTime'>Date Time</option>
                        <option value='json'>Json</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label
                        htmlFor={`key-${filterIndex}`}
                        className='form-label'
                      >
                        Key
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id={`key-${filterIndex}`}
                        name='key'
                        value={filter.key}
                        onChange={(e) =>
                          props.handleFilterChange(filterIndex, e)
                        }
                      />
                    </div>
                  </div>
                  {filter.dataType === 'json' && (
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label
                          htmlFor={`l2key-${filterIndex}`}
                          className='form-label'
                        >
                          L2 Key
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id={`l2key-${filterIndex}`}
                          name='l2key'
                          value={filter.l2key}
                          onChange={(e) =>
                            props.handleFilterChange(filterIndex, e)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                {(filter.dataType === 'intNumber' ||
                  filter.dataType === 'floatNumber' ||
                  filter.dataType === 'dateTime') && (
                  <div className='mb-3 form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id={`isRangesAvailable-${filterIndex}`}
                      name='isRangesAvailable'
                      checked={filter.isRangesAvailable}
                      onChange={(e) => props.handleFilterChange(filterIndex, e)}
                    />
                    <label
                      className='form-check-label'
                      htmlFor={`isRangesAvailable-${filterIndex}`}
                    >
                      Is Ranges Available
                    </label>
                  </div>
                )}
                {filter.isRangesAvailable && (
                  <div className='mt-3'>
                    {filter.ranges.length > 0 && (
                      <label
                        htmlFor={`ranges-${filterIndex}`}
                        className='form-label'
                      >
                        Ranges
                      </label>
                    )}
                    {filter.ranges.map((range: any, rangeIndex: number) => (
                      <div
                        key={rangeIndex}
                        className='row mb-3 align-items-center'
                      >
                        <div className='col'>
                          <input
                            type='text'
                            className='form-control'
                            id={`min-${filterIndex}-${rangeIndex}`}
                            name='min'
                            value={range.min}
                            onChange={(e) =>
                              props.handleRangeInputChange(
                                filterIndex,
                                rangeIndex,
                                e,
                              )
                            }
                            placeholder='Min'
                          />
                        </div>
                        <div className='col-1 text-center'>-</div>
                        <div className='col'>
                          <input
                            type='text'
                            className='form-control'
                            id={`max-${filterIndex}-${rangeIndex}`}
                            name='max'
                            value={range.max}
                            onChange={(e) =>
                              props.handleRangeInputChange(
                                filterIndex,
                                rangeIndex,
                                e,
                              )
                            }
                            placeholder='Max'
                          />
                        </div>
                        <div className='col-1'>
                          <button
                            type='button'
                            className='btn btn-danger'
                            onClick={() =>
                              props.removeRangeInput(filterIndex, rangeIndex)
                            }
                          >
                            <BsTrash />
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type='button'
                      className='btn btn-primary mt-2'
                      onClick={() => props.addRangeInput(filterIndex)}
                    >
                      Add Range
                    </button>
                  </div>
                )}
                <div className='d-flex justify-content-end mt-3'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => props.removeFilter(filterIndex)}
                  >
                    Remove Filter
                  </button>
                </div>
                <hr />
              </div>
            ))}
            <div className='mb-3'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={props.addFilter}
              >
                Add Filter
              </button>
            </div>
            {/* <div className='text-end'>
              <button type='submit' className='btn btn-primary mb-3'>
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersDetails;
