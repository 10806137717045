// import React, { useState, ChangeEvent } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// interface SubscriptionTool {
//   id: string;
//   name: string;
//   status: boolean;
//   created_at: string;
//   updated_at: string;
// }

// const initialTools: SubscriptionTool[] = [
//   {
//     id: 'tool1',
//     name: 'Tool 1',
//     status: true,
//     created_at: '2024-01-01T12:00:00Z',
//     updated_at: '2024-01-01T12:00:00Z',
//   },
//   {
//     id: 'tool2',
//     name: 'Tool 2',
//     status: false,
//     created_at: '2024-01-02T12:00:00Z',
//     updated_at: '2024-01-02T12:00:00Z',
//   },
//   // Add more tools as needed
// ];

// const SubscriptionTools: React.FC = () => {
//   const [tools, setTools] = useState<SubscriptionTool[]>(initialTools);
//   const [modalShow, setModalShow] = useState(false);
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [formValues, setFormValues] = useState<Partial<SubscriptionTool>>({});

//   const handleShowModal = (id?: string) => {
//     if (id) {
//       const tool = tools.find((tool) => tool.id === id);
//       if (tool) {
//         setFormValues(tool);
//         setEditingId(id);
//       }
//     } else {
//       setFormValues({});
//       setEditingId(null);
//     }
//     setModalShow(true);
//   };

//   const handleCloseModal = () => setModalShow(false);

//   const handleChange = (
//     e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
//   ) => {
//     const { name, value, type, checked } = e.target as any;
//     setFormValues((prevState: any) => ({
//       ...prevState,
//       [name]: type === 'checkbox' ? checked : value,
//     }));
//   };

//   const handleSave = () => {
//     if (editingId) {
//       setTools((prevState) =>
//         prevState.map((tool) =>
//           tool.id === editingId ? (formValues as SubscriptionTool) : tool,
//         ),
//       );
//     } else {
//       const newTool: SubscriptionTool = {
//         id: (tools.length + 1).toString(),
//         name: formValues.name || '',
//         status: formValues.status || false,
//         created_at: new Date().toISOString(),
//         updated_at: new Date().toISOString(),
//       };
//       setTools([...tools, newTool]);
//     }
//     setModalShow(false);
//   };

//   return (
//     <div className='container mt-4'>
//       <h2>Subscription Tools</h2>
//       <Button
//         variant='primary'
//         className='mb-2'
//         onClick={() => handleShowModal()}
//       >
//         Add Tool
//       </Button>
//       <table className='table table-striped'>
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Status</th>
//             <th>Created At</th>
//             <th>Updated At</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {tools.map((tool) => (
//             <tr key={tool.id}>
//               <td>{tool.name}</td>
//               <td>{tool.status ? 'Active' : 'Inactive'}</td>
//               <td>{new Date(tool.created_at).toLocaleString()}</td>
//               <td>{new Date(tool.updated_at).toLocaleString()}</td>
//               <td>
//                 <Button
//                   variant='secondary'
//                   onClick={() => handleShowModal(tool.id)}
//                 >
//                   Edit
//                 </Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <Modal show={modalShow} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>{editingId ? 'Edit Tool' : 'Add Tool'}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId='formToolName'>
//               <Form.Label>Tool Name</Form.Label>
//               <Form.Control
//                 type='text'
//                 name='name'
//                 value={formValues.name || ''}
//                 onChange={handleChange as any}
//               />
//             </Form.Group>
//             <Form.Group controlId='formToolStatus' className='mt-3'>
//               <Form.Check
//                 type='checkbox'
//                 name='status'
//                 label='Tool Status'
//                 checked={formValues.status || false}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant='secondary' onClick={handleCloseModal}>
//             Close
//           </Button>
//           <Button variant='primary' onClick={handleSave}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default SubscriptionTools;

import React, { useState, useEffect, ChangeEvent } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

interface SubscriptionTool {
  id: string;
  name: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

const SubscriptionTools: React.FC = () => {
  const [tools, setTools] = useState<SubscriptionTool[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<Partial<SubscriptionTool>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const response = await fetch(
          'https://vto-api.metadome.ai/admin/subscription-tool',
        );
        if (!response.ok) {
          throw new Error('Failed to fetch tools');
        }
        const data = await response.json();
        setTools(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTools();
  }, []);

  const handleShowModal = (id?: string) => {
    if (id) {
      const tool = tools.find((tool) => tool.id === id);
      if (tool) {
        setFormValues(tool);
        setEditingId(id);
      }
    } else {
      setFormValues({});
      setEditingId(null);
    }
    setModalShow(true);
  };

  const handleCloseModal = () => setModalShow(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type, checked } = e.target as any;
    setFormValues((prevState: any) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSave = async () => {
    try {
      console.log(formValues);
      let response;
      if (editingId) {
        response = await fetch(
          `https://vto-api.metadome.ai/admin/subscription-tool/${editingId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access_token')} ${localStorage.getItem('refresh_token')}`,
            },
            body: JSON.stringify(formValues),
          },
        );
      } else {
        response = await fetch(
          'https://vto-api.metadome.ai/admin/subscription-tool',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access_token')} ${localStorage.getItem('refresh_token')}`,
            },
            body: JSON.stringify(formValues),
          },
        );
      }

      if (!response.ok) {
        throw new Error('Failed to save tool');
      }

      const id = await response.text();

      const savedTool = {
        ...formValues,
        id,
        updated_at: new Date().toISOString(),
        created_at: editingId
          ? formValues.created_at
          : new Date().toISOString(),
      };

      setTools((prevState: any) =>
        editingId
          ? prevState.map((tool: any) =>
            tool.id === editingId ? savedTool : tool,
          )
          : [...prevState, savedTool],
      );
    } catch (err: any) {
      setError(err.message);
    } finally {
      setModalShow(false);
    }
  };

  return (
    <div className='container mt-4'>
      <h2>Subscription Tools</h2>
      {loading ? (
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      ) : error ? (
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      ) : (
        <>
          <Button
            variant='primary'
            className='mb-2'
            onClick={() => handleShowModal()}
          >
            Add Tool
          </Button>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tools.length === 0 && (
                <tr>
                  <td colSpan={5}>No tools found</td>
                </tr>
              )}
              {tools.map((tool) => (
                <tr key={tool.id}>
                  <td>{tool.name}</td>
                  <td>{tool.status ? 'Active' : 'Inactive'}</td>
                  <td>{new Date(tool.created_at).toLocaleString()}</td>
                  <td>{new Date(tool.updated_at).toLocaleString()}</td>
                  <td>
                    <Button
                      variant='secondary'
                      onClick={() => handleShowModal(tool.id)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <Modal show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingId ? 'Edit Tool' : 'Add Tool'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='formToolName'>
              <Form.Label>Tool Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                value={formValues.name || ''}
                onChange={handleChange as any}
              />
            </Form.Group>
            <Form.Group controlId='formToolStatus' className='mt-3'>
              <Form.Check
                type='checkbox'
                name='status'
                label='Tool Status'
                checked={formValues.status || false}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubscriptionTools;
