import { Form, FormLayout, Text, TextField, Button, Frame, Toast } from '@shopify/polaris';
import axios from 'axios';
import { useState } from 'react';
import { getAppUrl } from '../components/GlobalVariables';

export default function ContactUs({ }) {
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')
  const app_server_url = getAppUrl();
  const [showSubmitted, setShowSubmitted] = useState(false)
  const [showError, setShowError] = useState(false)

  const handleSubmit = () => {

    if(!firstname || !lastname || !email){
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 6000);
      return
    }
    const config = {
      method: 'post',
      url: `${app_server_url}/shopify-plugin/email`,
      headers: {
        'Content-Type': 'application/json'
      },
      CC: [""],
      data: JSON.stringify({
        'FromAddress': 'noreply@metadome.ai',
        'FromName': "Metadome",
        "Recipients": [
          "gaurav@metadome.ai",
          "info@metadome.ai"
        ],
        "Subject": "Query: from Shopify",
        "HTML": `
          <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Email Template</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f7f7f7;
        }
        .email-container {
          max-width: 600px;
          margin: 0 auto;
          background-color: white;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .header {
          background-color: #4caf50;
          color: white;
          padding: 20px;
          text-align: center;
        }
        .content {
          padding: 20px;
          color: #333;
        }
        .button {
          display: inline-block;
          padding: 10px 20px;
          background-color: #4caf50;
          color: white;
          text-decoration: none;
          margin-top: 10px;
        }
        .footer {
          text-align: center;
          color: white;
          background-color: #333;
          padding: 20px;
          margin-top: 20px;
        }
      </style>
    </head>
    <body>
      <div class="email-container">
        <div class="header">
          <h1>Welcome</h1>
        </div>
  
        <div class="content">
          <p>Hello there!</p>
          <p>
            Name: ${firstname + lastname}
            <br />
            Email: ${email}
            <br />
            Query: ${description} 
          </p>
        </div>
  
        <div class="footer">
          <p>&copy; Meadome.ai, All Rights Reserved.</p>
        </div>
      </div>
    </body>
  </html>
  `
      })
    }

    axios.request(config).then(() => {
      setShowSubmitted(true)
      setFirstName('')
      setLastName('')
      setEmail('')
      setDescription('')
      setTimeout(() => {
        setShowSubmitted(false)
      }, 8000);
    })
  }

  const NotifyError = showError ? (
    <Toast content={`Please enter all the details to submit.`} onDismiss={() => { setShowError(false) }} />
  ) : null;

  const NotifySubmit = showSubmitted ? (
    <Toast content={`Form submitted! We will get in touch with you soon.`} onDismiss={() => { setShowSubmitted(false) }} />
  ) : null;
  return (
    <Frame>
      {NotifyError}
      {NotifySubmit}
      <div style={{ display: 'flex', flexDirection: 'column', gap: "15px" }}>
        <Text as='h4' fontWeight='bold'>Get in touch with us</Text>
        <Form onSubmit={() => {
          handleSubmit()
        }}>
          <FormLayout>
            <div style={{ width: '40%' }}>
              <TextField
                value={firstname}
                onChange={setFirstName}
                label="First Name"
                autoComplete='off'
                type="text"
              />
            </div>
            <div style={{ width: '40%' }}>
              <TextField
                value={lastname}
                onChange={setLastName}
                label="Last Name"
                type="text"
                autoComplete="off"
              />
            </div>
            <div style={{ width: '40%' }}>
              <TextField
                value={email}
                onChange={setEmail}
                label="Email"
                type="email"
                autoComplete="email"
                helpText={
                  <span>
                    We’ll use this email address to inform you on future changes.
                  </span>
                }
              />
            </div>
            <div style={{ width: '40%' }}>
              <TextField
                label="How can we help you?"
                value={description}
                onChange={setDescription}
                multiline={5}
                autoComplete="off"
              />
            </div>
            <Button submit variant="primary" tone='success'>Submit</Button>
          </FormLayout>
        </Form>
      </div>
    </Frame>
  );
}