import React from 'react';
import CustomTextInput from '../TextInput';

const BasicDetails = (props: any) => {
  let headersJSX: JSX.Element | null = null;
  switch (props.client.platformId) {
    case 'shopify':
      headersJSX = (
        <>
          <div className='col-md-6'>
            <label htmlFor='X-Shopify-Access-Token' className='form-label'>
              X-Shopify-Access-Token
            </label>
            <input
              type='text'
              className='form-control'
              id='X-Shopify-Access-Token'
              name='headers.X-Shopify-Access-Token'
              value={props.client.headers['X-Shopify-Access-Token']}
              onChange={props.handleChange}
            />
          </div>
          <div className='col-md-6'>
            <label
              htmlFor='X-Shopify-Storefront-Access-Token'
              className='form-label'
            >
              X-Shopify-Storefront-Access-Token
            </label>
            <input
              type='text'
              className='form-control'
              id='X-Shopify-Storefront-Access-Token'
              name='headers.X-Shopify-Storefront-Access-Token'
              value={props.client.headers['X-Shopify-Storefront-Access-Token']}
              onChange={props.handleChange}
            />
          </div>
        </>
      );
      break;
    case 'magento':
      headersJSX = (
        <>
          <div className='col-md-6'>
            <label htmlFor='username' className='form-label'>
              User Name
            </label>
            <input
              type='text'
              className='form-control'
              id='username'
              name='headers.username'
              value={props.client.headers['username']}
              onChange={props.handleChange}
            />
          </div>
          <div className='col-md-6'>
            <label htmlFor='password' className='form-label'>
              Password
            </label>
            <input
              type='text'
              className='form-control'
              id='password'
              name='headers.password'
              value={props.client.headers['password']}
              onChange={props.handleChange}
            />
          </div>
        </>
      );
      break;
    case 'woocommerce':
      headersJSX = (
        <>
          <div className='col-md-4'>
            <label htmlFor='consumerKey' className='form-label'>
              Consumer Key
            </label>
            <input
              type='text'
              className='form-control'
              id='consumerKey'
              name='headers.consumerKey'
              value={props.client.headers['consumerKey']}
              onChange={props.handleChange}
            />
          </div>
          <div className='col-md-4'>
            <label htmlFor='consumerSecret' className='form-label'>
              Consumer Secret
            </label>
            <input
              type='text'
              className='form-control'
              id='consumerSecret'
              name='headers.consumerSecret'
              value={props.client.headers['consumerSecret']}
              onChange={props.handleChange}
            />
          </div>
          <div className='col-md-4'>
            <label htmlFor='version' className='form-label'>
              version
            </label>
            <input
              disabled={true}
              type='text'
              className='form-control'
              id='version'
              name='headers.version'
              value={'wc/v3'}
              onChange={props.handleChange}
            />
          </div>
        </>
      );
      break;
    default:
  }

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='basicDetails'>
        <button
          className={
            'accordion-button' + (props.showBasicDetails ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseBasicDetails'
          aria-expanded={props.showBasicDetails ? 'true' : 'false'}
          aria-controls='collapseBasicDetails'
          onClick={(e) => {
            e.preventDefault();
            props.setShowBasicDetails(
              (showBasicDetails: boolean) => !showBasicDetails,
            );
          }}
        >
          Basic Details
        </button>
      </h2>
      <div
        id='collapseBasicDetails'
        className={`accordion-collapse collapse ${
          props.showBasicDetails ? 'show' : ''
        }`}
        aria-labelledby='basicDetails'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='name' className='form-label'>
                  Client Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  name='name'
                  value={props.client.name}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='clientSPOC'
                label='Client SPOC'
                type='text'
                id='clientSPOC'
                name='clientSPOC'
                value={props.client.clientSPOC}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='email' className='form-label'>
              SPOC Email
            </label>
            <input
              type='email'
              className='form-control'
              id='email'
              name='email'
              value={props.client.email}
              onChange={props.handleChange}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='description' className='form-label'>
              Project Description
            </label>
            <textarea
              className='form-control'
              id='description'
              name='description'
              value={props.client.description}
              onChange={props.handleChange as any}
            />
          </div>
          <div className='row'>
            {/* <div className='col-md-4'>
              <div className='form-group form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='showOutOfStock'
                  name='showOutOfStock'
                  checked={props.client.showOutOfStock}
                  onChange={props.handleChange}
                />
                <label className='form-check-label' htmlFor='showOutOfStock'>
                  Show Out Of Stock
                </label>
              </div>
            </div> */}
            {/* <div className='col-md-4'>
              <div className='form-group form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='autoSync'
                  name='autoSync'
                  checked={props.client.autoSync}
                  onChange={props.handleChange}
                />
                <label className='form-check-label' htmlFor='autoSync'>
                  Auto Sync
                </label>
              </div>
            </div> */}
            <div className='col-md-4'>
              <div className='form-group form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='isFinalOutputSDK'
                  name='isFinalOutputSDK'
                  checked={props.client.isFinalOutputSDK}
                  onChange={props.handleChange}
                />
                <label className='form-check-label' htmlFor='isFinalOutputSDK'>
                  Is Final Output SDK
                </label>
              </div>
            </div>
          </div>
          {/*
          {props.client.autoSync && (
            <div className='form-group'>
              <label htmlFor='syncIntervalSeconds' className='form-label'>
                Sync Interval Seconds
              </label>
              <input
                type='number'
                className='form-control'
                id='syncIntervalSeconds'
                name='syncIntervalSeconds'
                value={props.client.syncIntervalSeconds}
                onChange={props.handleChange}
              />
            </div>
          )}
           <div className='form-group'>
            <label htmlFor='platformId' className='form-label'>
              Platform
            </label>
            <select
              className='form-control'
              id='platformId'
              name='platformId'
              value={props.client.platformId}
              onChange={props.handleChange as any}
            >
              <option value=''>Select Platform</option>
              <option value='shopify'>Shopify</option>
              <option value='magento'>Magento</option>
              <option value='woocommerce'>Woocommerce</option>
            </select>
          </div> */}
          {/* <div className='mb-3'>
            {props.client.platformId && props.client.platformId !== '' && (
              <label htmlFor='headers' className='form-label'>
                Headers
              </label>
            )}
            <div className='ml-4 row'>{headersJSX}</div>
          </div>

          {props.client.platformId !== '' && (
            <div className='form-group'>
              <label htmlFor='storeUrl' className='form-label'>
                Store URL
              </label>
              <input
                type='text'
                className='form-control'
                id='storeUrl'
                name='storeUrl'
                value={props.client.storeUrl}
                onChange={props.handleChange}
              />
            </div>
          )}
          {props.client.platformId === 'shopify' && (
            <div className='form-group'>
              <label htmlFor='storeAdminUrl' className='form-label'>
                Store Admin URL
              </label>
              <input
                type='text'
                className='form-control'
                id='storeAdminUrl'
                name='storeAdminUrl'
                value={props.client.storeAdminUrl}
                onChange={props.handleChange}
              />
            </div>
          )}

          <CustomTextInput
            htmlFor='globalTag'
            label='Global Tag'
            type='text'
            id='globalTag'
            name='globalTag'
            value={props.client.globalTag}
            onChange={props.handleChange}
          /> */}

          <div className='row'>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='subscriptionStartTime'
                label='Subscription Start Time'
                type='date'
                id='subscriptionStartTime'
                name='subscriptionStartTime'
                value={props.client.subscriptionStartTime}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='subscriptionEndTime'
                label='Subscription End Time'
                type='date'
                id='subscriptionEndTime'
                name='subscriptionEndTime'
                value={props.client.subscriptionEndTime}
                onChange={props.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
