import { useState } from "react";
import classes from "./subscriptions.module.scss";
import {
  Text,
  Card,
  List,
  Frame,
  Autocomplete,
  LegacyCard,
  IndexTable,
  Modal,
} from "@shopify/polaris";

export const subscriptionDetails = [
  {
    key: "free",
    name: "Free",
    price: 0,
    description: [
      "Photo/Live Try-On",
      "Upto 1k user sessions",
      "Unlimited active products",
      "Any 3 categories ",
      "All usage analytics",
    ],
    totalSessions: "1000",
    maxCategories: 3,
  },
  {
    key: "base",
    name: "Base",
    price: 99,
    description: [
      "14-day free trial",
      "Photo/Live Try-On",
      "Upto 50k user sessions",
      "Unlimited active products",
      "Any 3 categories ",
      "All usage analytics",
    ],
    totalSessions: "50k",
    maxCategories: 3,
  },
  {
    key: "pro",
    name: "Pro",
    price: 199,
    description: [
      "14-day free trial",
      "Photo/Live Try-On",
      "Upto 200k user sessions",
      "Unlimited active products",
      "Any 5 categories ",
      "All usage analytics",
    ],
    totalSessions: "200k",
    maxCategories: 5,
  },
];

export default function Subscription({
  showCancelConfirmPopup,
  setShowCancelConfirmPopup,
  cancelSubscription,
  addSubscription,
  goToContactUs,
  isFirstInstallation,
  currentSubscription,
}: any) {
  return (
    <div className={classes.container}>
      <div style={{ textAlign: "center" }}>
        <Text as="h3" fontWeight="bold">
          Choose a plan that's right for your business.
        </Text>
      </div>
      <div className={classes.subscriptionsContainer}>
        {subscriptionDetails.map((sub) => (
          <div className={classes.subscriptions} key={sub.key}>
            <Card>
              <div className={classes.container} style={{ gap: "25px" }}>
                <Text as="h4" alignment="center" fontWeight="bold">
                  {sub.name}
                </Text>
                {/* <Text as='p' variant="bodySm">Free AR virtual try-on package to experience the power of AR technology.</Text> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "4px",
                    justifyContent: "center",
                  }}
                >
                  <Text as="h2" fontWeight="bold">
                    ${sub.price} /
                  </Text>
                  <Text as="p" variant="bodySm">
                    month
                  </Text>
                </div>
                <List type="bullet">
                  {sub.description.map((point) => (
                    <List.Item>
                      <Text as="p" variant="bodySm">
                        {point}
                      </Text>
                    </List.Item>
                  ))}
                </List>
                {sub.key === "free" ? (
                  !isFirstInstallation || currentSubscription !== "free" ? (
                    <Text
                      alignment="center"
                      as="p"
                      variant="bodyLg"
                      fontWeight="bold"
                      tone="critical"
                    >
                      Free Plan is Exhausted
                    </Text>
                  ) : (
                    <button
                      style={{
                        width: "100%",
                        borderRadius: "20px",
                        border:
                          currentSubscription === sub.key
                            ? "1px solid rgba(4, 123, 93, 1)"
                            : "none",
                        backgroundColor:
                          currentSubscription === sub.key
                            ? "white"
                            : "rgba(4, 123, 93, 1)",
                        color:
                          currentSubscription === sub.key
                            ? "rgba(4, 123, 93, 1)"
                            : "white",
                        padding: "4px 0",
                      }}
                      onClick={
                        currentSubscription !== sub.key && sub.price
                          ? () => addSubscription(sub.price, sub.key)
                          : () => {}
                      }
                    >
                      {currentSubscription === sub.key
                        ? "Current Plan"
                        : "Choose Plan"}
                    </button>
                  )
                ) : (
                  <button
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      border:
                        currentSubscription === sub.key
                          ? "1px solid rgba(4, 123, 93, 1)"
                          : "none",
                      backgroundColor:
                        currentSubscription === sub.key
                          ? "white"
                          : "rgba(4, 123, 93, 1)",
                      color:
                        currentSubscription === sub.key
                          ? "rgba(4, 123, 93, 1)"
                          : "white",
                      padding: "4px 0",
                    }}
                    onClick={
                      currentSubscription !== sub.key && sub.price
                        ? () => addSubscription(sub.price, sub.key)
                        : () => {
                            setShowCancelConfirmPopup(true);
                          }
                    }
                  >
                    {currentSubscription === sub.key
                      ? "Cancel Plan"
                      : "Choose Plan"}
                  </button>
                )}
              </div>
            </Card>
          </div>
        ))}
      </div>
      {showCancelConfirmPopup && (
        <Frame>
          <Modal
            open={showCancelConfirmPopup}
            onClose={() => setShowCancelConfirmPopup(false)}
            title="Please Confirm to cancel the active subscription"
            primaryAction={{
              content: "Confirm",
              onAction: () => {
                cancelSubscription()
                  .then(() => {
                    setShowCancelConfirmPopup(false);
                  })
                  .catch((error: unknown) => {
                    setShowCancelConfirmPopup(false);
                  });
              },
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: () => {
                  setShowCancelConfirmPopup(false);
                },
              },
            ]}
          >
            <Modal.Section>
              <Text as="strong">
                Note: The application won't continue until you have an active
                plan since Free Plan is already exhausted
              </Text>
            </Modal.Section>
          </Modal>
        </Frame>
      )}
    </div>
  );
}
