import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';  // Adjust the locale as needed
import '@shopify/polaris/build/esm/styles.css';
import App from './App';
require('./utils/sentry')
ReactDOM.render(
  <AppProvider i18n={translations}>
    <App />
  </AppProvider>,
  document.getElementById('root')
);
