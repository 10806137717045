// import React, { useState, ChangeEvent } from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import { useLocation } from 'react-router-dom';

// interface SubscriptionTool {
//   id: string;
//   name: string;
//   status: boolean;
//   created_at: string;
//   updated_at: string;
// }

// interface ClientSubscription {
//   id: string;
//   client_status: boolean;
//   admin_status: boolean;
//   subscriptionTool: SubscriptionTool;
//   created_at: string;
//   updated_at: string;
// }

// const subscriptionTools: SubscriptionTool[] = [
//   {
//     id: 'tool1',
//     name: 'Tool 1',
//     status: true,
//     created_at: '2024-01-01T12:00:00Z',
//     updated_at: '2024-01-01T12:00:00Z',
//   },
//   {
//     id: 'tool2',
//     name: 'Tool 2',
//     status: false,
//     created_at: '2024-01-02T12:00:00Z',
//     updated_at: '2024-01-02T12:00:00Z',
//   },
// ];

// const initialSubscriptions: ClientSubscription[] = [
//   {
//     id: '1',
//     client_status: true,
//     admin_status: false,
//     subscriptionTool: subscriptionTools[0],
//     created_at: '2024-01-01T12:00:00Z',
//     updated_at: '2024-01-01T12:00:00Z',
//   },
//   {
//     id: '2',
//     client_status: false,
//     admin_status: true,
//     subscriptionTool: subscriptionTools[1],
//     created_at: '2024-01-02T12:00:00Z',
//     updated_at: '2024-01-02T12:00:00Z',
//   },
// ];

// const ClientSubscriptions = (props: any) => {
//   const [subscriptions, setSubscriptions] =
//     useState<ClientSubscription[]>(initialSubscriptions);
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [formValues, setFormValues] = useState<any>({});
//   const [showModal, setShowModal] = useState(false);

//   const { state } = useLocation();
//   const { name } = state;

//   console.log(state);

//   const handleEdit = (id: string) => {
//     const subscription = subscriptions.find((sub) => sub.id === id);
//     if (subscription) {
//       setFormValues(subscription);
//       setEditingId(id);
//       setShowModal(true);
//     }
//   };

//   const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
//     const { name, value, type, checked } = e.target;
//     setFormValues((prevState: any) => ({
//       ...prevState,
//       [name]: type === 'checkbox' ? checked : value,
//       subscriptionTool: {
//         ...prevState.subscriptionTool,
//         [name]: type === 'checkbox' ? checked : value,
//       },
//     }));
//   };

//   const handleSave = () => {
//     setSubscriptions((prevState) =>
//       prevState.map((sub) =>
//         sub.id === editingId ? (formValues as ClientSubscription) : sub,
//       ),
//     );
//     setEditingId(null);
//     setFormValues({});
//     setShowModal(false);
//   };

//   return (
//     <div className='d-flex flex-column min-vh-100'>
//       <nav className='navbar navbar-expand-lg navbar-light bg-light'>
//         <div className='navbar-brand ml-3 mb-1'>
//           <span className='fw-bold'>"{name}"</span> Subscriptions
//         </div>
//       </nav>
//       <div className='flex-grow-1 p-3 d-flex justify-content-center'>
//         <div className='w-75'>
//           <table className='table table-bordered'>
//             <thead>
//               <tr>
//                 <th className='text-center align-middle'>Client Status</th>
//                 <th className='text-center align-middle'>Admin Status</th>
//                 <th className='text-center align-middle'>Tool Name</th>
//                 <th className='text-center align-middle'>Tool Status</th>
//                 <th className='text-center align-middle'>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {subscriptions.map((sub) => (
//                 <tr key={sub.id}>
//                   <td className='text-center align-middle'>
//                     {sub.client_status ? 'Active' : 'Inactive'}
//                   </td>
//                   <td className='text-center align-middle'>
//                     {sub.admin_status ? 'Active' : 'Inactive'}
//                   </td>
//                   <td className='text-center align-middle'>
//                     {sub.subscriptionTool.name}
//                   </td>
//                   <td className='text-center align-middle'>
//                     {sub.subscriptionTool.status ? 'Active' : 'Inactive'}
//                   </td>
//                   <td className='text-center align-middle'>
//                     <button
//                       onClick={() => handleEdit(sub.id)}
//                       className='btn btn-secondary'
//                     >
//                       Edit
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>

//           <Modal show={showModal} onHide={() => setShowModal(false)}>
//             <Modal.Header closeButton>
//               <Modal.Title>Edit Subscription</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <div className='form-group mb-3'>
//                 <label className='form-check-label'>
//                   Client Status:
//                   <input
//                     type='checkbox'
//                     name='client_status'
//                     checked={formValues.client_status || false}
//                     onChange={handleChange}
//                     className='form-check-input ms-2'
//                   />
//                 </label>
//               </div>
//               <div className='form-group mb-3'>
//                 <label className='form-check-label'>
//                   Admin Status:
//                   <input
//                     type='checkbox'
//                     name='admin_status'
//                     checked={formValues.admin_status || false}
//                     onChange={handleChange}
//                     className='form-check-input ms-2'
//                   />
//                 </label>
//               </div>
//               <div className='form-group mb-3'>
//                 <label htmlFor='name' className='form-label'>
//                   Tool Name:
//                 </label>
//                 <input
//                   type='text'
//                   name='name'
//                   id='name'
//                   value={formValues.subscriptionTool?.name || ''}
//                   onChange={handleChange}
//                   className='form-control'
//                 />
//               </div>
//               <div className='form-group mb-3'>
//                 <label className='form-check-label'>
//                   Tool Status:
//                   <input
//                     type='checkbox'
//                     name='status'
//                     checked={formValues.subscriptionTool?.status || false}
//                     onChange={handleChange}
//                     className='form-check-input ms-2'
//                   />
//                 </label>
//               </div>
//             </Modal.Body>
//             <Modal.Footer>
//               <Button variant='secondary' onClick={() => setShowModal(false)}>
//                 Close
//               </Button>
//               <Button variant='primary' onClick={handleSave}>
//                 Save Changes
//               </Button>
//             </Modal.Footer>
//           </Modal>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ClientSubscriptions;

import React, { useState, useEffect, ChangeEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

interface SubscriptionTool {
  id: string;
  name: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

interface ClientSubscription {
  id: string;
  client_status: boolean;
  admin_status: boolean;
  subscriptionTool: SubscriptionTool;
  created_at: string;
  updated_at: string;
}

const ClientSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<ClientSubscription[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { client } = state;

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    navigate("/admin/login");
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://vto-api.metadome.ai/client-subscription/${client.Id}`
        );
        const data = await response.json();
        const tools = await fetch(
          "https://vto-api.metadome.ai/admin/subscription-tool",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "access_token"
              )} ${localStorage.getItem("refresh_token")}`,
            },
          }
        );
        setSubscriptions(data);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleEdit = (id: string) => {
    const subscription = subscriptions.find((sub) => sub.id === id);
    if (subscription) {
      setFormValues(subscription);
      setEditingId(id);
      setShowModal(true);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevState: any) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
      subscriptionTool: {
        ...prevState.subscriptionTool,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  const handleSave = async () => {
    if (editingId) {
      try {
        const response = await fetch(
          `https://vto-api.metadome.ai/client-subscription/admin/${editingId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "access_token"
              )} ${localStorage.getItem("refresh_token")}`,
            },
            body: JSON.stringify(formValues),
          }
        );

        if (response.ok) {
          setSubscriptions((prevState) =>
            prevState.map((sub) =>
              sub.id === editingId ? (formValues as ClientSubscription) : sub
            )
          );
        } else {
          console.error("Failed to update subscription");
        }
      } catch (error) {
        console.error("Error updating subscription:", error);
      }
    }
    setEditingId(null);
    setFormValues({});
    setShowModal(false);
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="navbar-brand ml-3 mb-1">
          <span className="fw-bold">"{client.name}"</span> Subscriptions
        </div>

        <div className="align-items-center mr-3 ml-auto">
          <button className="btn btn-danger" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </nav>
      <div className="flex-grow-1 p-3 d-flex justify-content-center">
        <div className="w-75">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center align-middle">Client Status</th>
                <th className="text-center align-middle">Admin Status</th>
                <th className="text-center align-middle">Tool Name</th>
                <th className="text-center align-middle">Tool Status</th>
                <th className="text-center align-middle">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={5} className="text-center">
                    Loading...
                  </td>
                </tr>
              )}
              {subscriptions.length === 0 && (
                <tr>
                  <td colSpan={5}>No subscriptions found</td>
                </tr>
              )}
              {subscriptions.map((sub) => (
                <tr key={sub.id}>
                  <td className="text-center align-middle">
                    {sub.client_status ? "Active" : "Inactive"}
                  </td>
                  <td className="text-center align-middle">
                    {sub.admin_status ? "Active" : "Inactive"}
                  </td>
                  <td className="text-center align-middle">
                    {sub.subscriptionTool.name}
                  </td>
                  <td className="text-center align-middle">
                    {sub.subscriptionTool.status ? "Active" : "Inactive"}
                  </td>
                  <td className="text-center align-middle">
                    <button
                      onClick={() => handleEdit(sub.id)}
                      className="btn btn-secondary"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group mb-3">
                <label className="form-check-label">
                  Client Status:
                  <input
                    type="checkbox"
                    name="client_status"
                    checked={formValues.client_status || false}
                    onChange={handleChange}
                    className="form-check-input ms-2"
                  />
                </label>
              </div>
              <div className="form-group mb-3">
                <label className="form-check-label">
                  Admin Status:
                  <input
                    type="checkbox"
                    name="admin_status"
                    checked={formValues.admin_status || false}
                    onChange={handleChange}
                    className="form-check-input ms-2"
                  />
                </label>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="name" className="form-label">
                  Tool Name:
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formValues.subscriptionTool?.name || ""}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-check-label">
                  Tool Status:
                  <input
                    type="checkbox"
                    name="status"
                    checked={formValues.subscriptionTool?.status || false}
                    onChange={handleChange}
                    className="form-check-input ms-2"
                  />
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ClientSubscriptions;
