import CustomTextInput from '../TextInput';
import React from 'react';

const ClientDetails = (props: any) => {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='clientDetails'>
        <button
          className={
            'accordion-button' + (props.showClientDetails ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseClientDetails'
          aria-expanded={props.showClientDetails ? 'true' : 'false'}
          aria-controls='collapseClientDetails'
          onClick={(e) => {
            e.preventDefault();
            props.setShowClientDetails(
              (showClientDetails: boolean) => !showClientDetails,
            );
          }}
        >
          Client Details
        </button>
      </h2>
      <div
        id='collapseClientDetails'
        className={`accordion-collapse collapse ${
          props.showClientDetails ? 'show' : ''
        }`}
        aria-labelledby='clientDetails'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='landingUrl'
                label='Landing Url'
                type='text'
                id='landingUrl'
                name='landingUrl'
                value={props.client.landingUrl}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='cartUrl'
                label='Cart Url'
                type='text'
                id='cartUrl'
                name='cartUrl'
                value={props.client.cartUrl}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='consentFormLink'
                label='Consent Form Link'
                type='text'
                id='consentFormLink'
                name='consentFormLink'
                value={props.client.consentFormLink}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='consentFormLinkName'
                label='Consent Form Link Name'
                type='text'
                id='consentFormLinkName'
                name='consentFormLinkName'
                value={props.client.consentFormLinkName}
                onChange={props.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
