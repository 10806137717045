import CustomTextInput from '../TextInput';
import React from 'react';

const AnalyticsDetails = (props: any) => {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='analyticsDetails'>
        <button
          className={
            'accordion-button' +
            (props.showAnalyticsDetails ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseAnalyticsDetails'
          aria-expanded={props.showAnalyticsDetails ? 'true' : 'false'}
          aria-controls='collapseAnalyticsDetails'
          onClick={(e) => {
            e.preventDefault();
            props.setShowAnalyticsDetails(
              (showAnalyticsDetails: boolean) => !showAnalyticsDetails,
            );
          }}
        >
          Analytics Details
        </button>
      </h2>
      <div
        id='collapseAnalyticsDetails'
        className={`accordion-collapse collapse ${
          props.showAnalyticsDetails ? 'show' : ''
        }`}
        aria-labelledby='analyticsDetails'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='gtm'
                label='gtm'
                type='text'
                id='gtm'
                name='gtm'
                value={props.client.gtm}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='mixpanel'
                label='mixpanel'
                type='text'
                id='mixpanel'
                name='mixpanel'
                value={props.client.mixpanel}
                onChange={props.handleChange}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='fbp'
                label='fbp'
                type='text'
                id='fbp'
                name='fbp'
                value={props.client.fbp}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-6'>
              <CustomTextInput
                htmlFor='ga'
                label='ga'
                type='text'
                id='ga'
                name='ga'
                value={props.client.ga}
                onChange={props.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AnalyticsDetails;
