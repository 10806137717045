import Creatable from 'react-select/creatable';
import Categories from '../Categories';
import React from 'react';

const Features = (props: any) => {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='features'>
        <button
          className={
            'accordion-button' + (props.showFeaturesDetails ? '' : ' collapsed')
          }
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseFeatures'
          aria-expanded={props.showFeaturesDetails ? 'true' : 'false'}
          aria-controls='collapseFeatures'
          onClick={(e) => {
            e.preventDefault();
            props.setShowFeaturesDetails(
              (showFeaturesDetails: boolean) => !showFeaturesDetails,
            );
          }}
        >
          Features
        </button>
      </h2>
      <div
        id='collapseFeatures'
        className={`accordion-collapse collapse ${
          props.showFeaturesDetails ? 'show' : ''
        }`}
        aria-labelledby='features'
        data-bs-parent='#accordionParent'
      >
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='foundationFinder' className='form-label'>
                  Foundation Finder
                </label>
                <select
                  className='form-control'
                  id='foundationFinder'
                  name='foundationFinder'
                  value={props.client.foundationFinder}
                  onChange={props.handleChange as any}
                >
                  <option value=''>Select</option>
                  <option value='true'>True</option>
                  <option value='false'>False</option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='tryOnModes' className='form-label'>
                  Try On Modes
                </label>
                <Creatable
                  isMulti
                  options={props.modes.map((mode: any) => ({
                    value: mode,
                    label: mode,
                  }))}
                  value={props.client.modes}
                  onChange={props.handleChangeModes as any}
                />
              </div>
            </div>
          </div>
          <div className='form-group form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              id='isLooksAvailable'
              name='isLooksAvailable'
              checked={props.client.isLooksAvailable}
              onChange={props.handleChange}
            />
            <label className='form-check-label' htmlFor='isLooksAvailable'>
              Is Looks Available
            </label>
          </div>

          {props.client.isLooksAvailable && (
            <div className='form-group'>
              <label htmlFor='looksTag' className='form-label'>
                Looks Tag
              </label>
              <input
                type='text'
                className='form-control'
                id='looksTag'
                name='looksTag'
                value={props.client.looksTag}
                onChange={props.handleChange}
              />
            </div>
          )}

          <div>
            <Categories
              setSubCategorySelections={props.setSubCategorySelections}
              subCategorySelections={props.subCategorySelections}
              selectedCategories={props.selectedCategories}
              setSelectedCategories={props.setSelectedCategories}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
