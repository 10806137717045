import React, { useState } from 'react';
import ClientForm from '../forms/clientForm';
import 'bootstrap/dist/css/bootstrap.min.css';

const ClientPage = () => {
  return (
    <div className='d-flex flex-column min-vh-100'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='navbar-brand ml-3 mb-1'>VTO Client Onboarding</div>
      </nav>
      <div className='flex-grow-1'>
        <ClientForm />
      </div>
    </div>
  );
};

export default ClientPage;

// <Routes>
//   <Route path='*' element={<Navigate to='client' />} />
//   <Route
//     path='client'
//     element={
//       <>
//         <ClientForm />
//       </>
//     }
//   />
//   <Route
//     path='category'
//     element={
//       <>
//         <CategoryForm />
//       </>
//     }
//   />
//   <Route
//     path='filter'
//     element={
//       <>
//         <FilterForm />
//       </>
//     }
//   />
// </Routes>;
