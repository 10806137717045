import React, { useState } from 'react';
import ClientForm from '../forms/clientForm';
import { useLocation } from 'react-router-dom';

const AdminClientFormPage = () => {
  const { state } = useLocation();
  // const client = {
  //   basicDetails: {
  //     name: 'Test Client',
  //     email: 'testclient@example.com',
  //     phone: '123-456-7890',
  //   },
  //   features: {
  //     modes: ['model', 'image'],
  //     categories: ['Category1', 'Category2'],
  //   },
  //   clientDetails: {
  //     address: '123 Test Street',
  //     city: 'Test City',
  //     country: 'Test Country',
  //   },
  //   brandGuideLines: {
  //     ctaColor: '#ff0000',
  //     brandColor: '#00ff00',
  //   },
  //   filters: [
  //     {
  //       name: 'Filter1',
  //       level: 'Level1',
  //       type: 'Type1',
  //       dataType: 'DataType1',
  //       key: 'Key1',
  //       l2key: 'L2Key1',
  //       isRangesAvailable: true,
  //       ranges: [{ min: '0', max: '10' }],
  //     },
  //   ],
  //   mediaAssets: [],
  //   downloadedImageDetails: [],
  //   analyticsDetails: {
  //     metric1: '100',
  //     metric2: '200',
  //   },
  // };

  console.log(state);

  return (
    <div className='container mt-3'>
      <ClientForm client={state.client} />
    </div>
  );
};

export default AdminClientFormPage;
