import { LegacyCard, Tabs, Text } from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
import Home from "./home";
import ProductIntegration from "./ProductIntegration";
import axios from "axios";
import {
  getApiKey,
  getApp,
  getAppUrl,
  getHost,
  getIsEmbedded,
  getIsFirstInstallation,
  getShop,
} from "../components/GlobalVariables";
import FAQ from "./faq";
import Subscription, { subscriptionDetails } from "./Subscription";
import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import ContactUs from "./contactus";
import classes from "./try.module.scss";
import { getSessionToken } from "@shopify/app-bridge/utilities";

interface ShopifyVariant {
  legacyResourceId: string;
  title: string;
  color: string;
}
export interface Variant {
  variantId: string;
  variantName: string;
  color: string;
}
export interface Product {
  id: string;
  productId: string;
  productName: string;
  storeName: string;
  makeup: string;
  variants: Variant[];
  imageUrl: string;
}

export default function Dashboard() {
  const [selected, setSelected] = useState(0);
  const [ShopifyProducts, setShopifyProducts] = useState<Product[]>([]);
  const [Localproducts, setLocalproducts] = useState<Product[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [currentSubscription, setCurrentSubscription] = useState("");
  const [currentSessions, setCurrentSessions] = useState<number>();
  const [selectedCategories, setSelectedCategories] = useState<any>();
  const [disableTabs, setDisableTabs] = useState(false);
  const [showCancelConfirmPopup, setShowCancelConfirmPopup] =
    useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<any>({});
  const [pageCursor, setPageCursor] = useState({ cursor: "", step: "" });
  const [searchVal, setSearchVal] = useState("");
  const app_server_url = getAppUrl();
  const shopName = getShop();
  const isFirstInstallation = getIsFirstInstallation();

  const getSelectedCategories = async () => {
    if (!shopName) return;
    try {
      const response = await axios.get(
        `${app_server_url}/shopify-plugin/makeup-categories?shop=${shopName}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );

      if (response?.data) setSelectedCategories(response.data);
    } catch (e) {}
  };

  const postSelectedCategories = async (categories: string[]) => {
    try {
      axios
        .post(
          `${app_server_url}/shopify-plugin/makeup-categories?shop=${shopName}`,
          categories,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
              skip_zrok_interstitial: "69420",
              authorization: `Bearer ${await getSessionToken(getApp())}`,
            },
          }
        )
        .then(() => {
          fetchLocalProducts();
        });
    } catch (e) {}
  };

  const getProductsByIds = (ids: string[]) => {
    const products = [];
    for (const id of ids) {
      products.push(allProducts.find((prod) => prod?.productId === id));
    }
    return products;
  };

  const addProduct = async (productIds: string[]) => {
    const products: any = getProductsByIds(productIds);
    let tempShopifyProducts = [...ShopifyProducts];
    for (const product of products) {
      tempShopifyProducts = tempShopifyProducts.filter(
        (prod) => prod?.productId !== product.productId
      );
    }
    setShopifyProducts(tempShopifyProducts);
    setLocalproducts([...Localproducts, ...products]);
  };

  const deleteProduct = async (productIds: string[]) => {
    const products: any = getProductsByIds(productIds);
    try {
      let tempLocalProducts = [...Localproducts];
      for (const product of products) {
        tempLocalProducts = tempLocalProducts.filter(
          (val) => val?.productId !== product?.productId
        );
      }
      setLocalproducts(tempLocalProducts);
      setShopifyProducts([...ShopifyProducts, ...products]);
      await axios.post(
        `${app_server_url}/shopify-plugin/delete-products?shop=${shopName}`,
        productIds,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );
    } catch (error) {
      console.error("Failed to delete product:", error);
    }
  };

  const saveProductInfo = async (productIds: string[]) => {
    let products: any = [];
    for (const id of productIds) {
      products.push(Localproducts.find((prod) => prod.productId === id));
    }
    try {
      await axios.post(
        `${app_server_url}/shopify-plugin/post-products?shop=${shopName}`,
        products,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );
    } catch (error) {
      console.error("Failed to save product edit:", error);
    }
  };

  const fetchLocalProducts = async () => {
    let LocalProducts: Product[] = [];
    try {
      const response = await axios.post(
        `${app_server_url}/shopify-plugin/get-products?shop=${shopName}`,
        {},
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );
      LocalProducts = (response.data as any[]).map((item) => {
        return {
          id: item.productId,
          productId: item.productId,
          productName: item.productName,
          storeName: item.storeName,
          makeup: item.makeup,
          variants: item.variants,
          imageUrl: item?.imageUrl || "",
        } as Product;
      });
    } catch (error) {
      console.error("Failed to fetch local products:", error);
    }
    return LocalProducts ? LocalProducts : [];
  };

  const fetchAllShopifyProducts = async () => {
    if (!shopName) return;
    try {
      if (!app_server_url || !shopName) return;
      let url = `${app_server_url}/shopify-plugin/shopifyProducts?shop=${shopName}`;
      if (pageCursor.cursor && pageCursor.step) {
        url = url + "&cursor=" + pageCursor.cursor + "&page=" + pageCursor.step;
      }
      if (searchVal) url = url + "&search=" + searchVal;

      const response = await axios.get(url, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      });
      const LocalProducts = await fetchLocalProducts();
      setLocalproducts(LocalProducts);
      const tempShopifyProducts: Product[] = [];
      setPaginationInfo(response.data.data.products.pageInfo);
      const AllShopifyProducts = (
        response.data.data.products.nodes as any[]
      )?.map((item) => {
        const product = {
          id: item.legacyResourceId.toString(),
          productId: item.legacyResourceId.toString(),
          productName: item.title,
          storeName: shopName,
          makeup: "lipstick",
          variants: item.variants.nodes.map((variant: ShopifyVariant) => {
            return {
              variantId: variant.legacyResourceId.toString(),
              variantName: variant.title,
              color: variant?.color || "#000000",
            } as Variant;
          }),
          imageUrl: item?.featuredMedia?.preview?.image?.url,
        } as Product;
        const isPresentInLocal = LocalProducts.find(
          (prod) => prod.productId === item.legacyResourceId.toString()
        );
        if (!isPresentInLocal) tempShopifyProducts.push(product);
        return product;
      });
      setShopifyProducts(tempShopifyProducts);
      setAllProducts(AllShopifyProducts || []);
    } catch (error) {
      console.error("Failed to fetch shopify products:", error);
    }
  };

  const fetchSubscriptionData = async () => {
    if (!shopName) return;
    try {
      const response = await axios.get(
        `${app_server_url}/shopify-plugin/recurring_application_charges?shop=${shopName}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );
      if (!response?.data) setCurrentSubscription(subscriptionDetails[0].key);
      else setCurrentSubscription(response.data.planName);
      if (
        !isFirstInstallation &&
        (response?.data?.planName === "free" || !response?.data?.planName)
      ) {
        handleTabChange(4);
        setDisableTabs(true);
      } else {
        handleTabChange(0);
        setDisableTabs(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addSubscription = async (price: number, name: string) => {
    try {
      const response = await axios.post(
        `${app_server_url}/shopify-plugin/recurring_application_charges?shop=${shopName}&host=${getHost()}`,
        {
          planPrice: price,
          planName: name,
          storeName: shopName,
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );
      if (response.data.url) {
        if (getIsEmbedded() === 1) {
          const config = {
            apiKey: getApiKey(),
            host: getHost(),
            forceRedirect: true,
          };
          const app = createApp(config);
          const redirect = Redirect.create(app);
          redirect.dispatch(Redirect.Action.REMOTE, response.data.url);
        } else window.location.replace(response.data.url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancelSubscription = async (price: number, name: string) => {
    try {
      const response = await axios.delete(
        `${app_server_url}/shopify-plugin/recurring_application_charges?shop=${shopName}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );
      if (response?.status === 200 && response?.data !== false) {
        handleTabChange(4);
        setDisableTabs(true);
        setCurrentSubscription("");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSessions = async () => {
    if (!shopName) return;
    try {
      const response = await axios.get(
        `${app_server_url}/shopify-plugin/sessions?shop=${shopName}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      );
      setCurrentSessions(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSessions();
    getSelectedCategories();
  }, [app_server_url, shopName]);

  useEffect(() => {
    fetchAllShopifyProducts();
  }, [app_server_url, shopName, pageCursor, searchVal]);

  useEffect(() => {
    fetchSubscriptionData();
  }, [isFirstInstallation, shopName, app_server_url]);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    []
  );

  const Analytics = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Text as="h3" fontWeight="bold">
          Analytics
        </Text>
        <img
          loading="lazy"
          style={{ width: "100%" }}
          src="https://virt-assets.metadome.ai/logo/analytics.png"
          alt="Analytics Logo"
        />
      </div>
    );
  };

  const tabs = [
    {
      id: "home",
      content: "Home",
      panelID: "home",
      component: (
        <Home
          currentPlan={subscriptionDetails.find(
            (plan) => plan.key === currentSubscription
          )}
          goToProducts={() => handleTabChange(1)}
          goToAnalytics={() => handleTabChange(2)}
          goToContactUs={() => handleTabChange(5)}
          goToPlans={() => handleTabChange(4)}
          goToFaq={() => handleTabChange(3)}
          currentSessions={currentSessions}
        />
      ),
    },
    {
      id: "product",
      content: "Product Integration",
      panelID: "product",
      component: (
        <ProductIntegration
          allProducts={allProducts}
          LocalProducts={Localproducts}
          ShopifyProducts={ShopifyProducts}
          setLocalProducts={setLocalproducts}
          addProduct={addProduct}
          saveProductInfo={saveProductInfo}
          deleteProduct={deleteProduct}
          selectedCategoriesRes={selectedCategories}
          setSelectedCategoriesRes={setSelectedCategories}
          postSelectedCategories={postSelectedCategories}
          currentPlan={subscriptionDetails.find(
            (plan) => plan.key === currentSubscription
          )}
          paginationInfo={paginationInfo}
          setPaginationInfo={setPaginationInfo}
          setPageCursor={setPageCursor}
          apiSearchVal={searchVal}
          setApiSearchVal={setSearchVal}
        />
      ),
    },
    {
      id: "analytics",
      content: "Analytics",
      panelID: "analytics",
      component: <Analytics />,
    },
    {
      id: "faq",
      content: "FAQ",
      panelID: "faq",
      component: <FAQ />,
    },
    {
      id: "subscription",
      content: "Choose Plan",
      panelID: "subscription",
      component: (
        <Subscription
          isFirstInstallation={isFirstInstallation}
          goToContactUs={() => handleTabChange(5)}
          currentSubscription={currentSubscription}
          showCancelConfirmPopup={showCancelConfirmPopup}
          setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          addSubscription={addSubscription}
          cancelSubscription={cancelSubscription}
        />
      ),
    },
    {
      id: "contactus",
      content: "Contact Us",
      panelID: "contactus",
      component: <ContactUs />,
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      selected={selected}
      onSelect={handleTabChange}
      fitted
      disabled={disableTabs}
    >
      <div className={classes.spacing}>
        <LegacyCard.Section
          title={tabs[selected].component}
        ></LegacyCard.Section>
      </div>
    </Tabs>
  );
}
